/* QuizVue scss component */

@use 'utilities/mq';
@use 'utilities/colors';

/*****************/
// Transition scss
/*****************/

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: 0.5s;
}

.fade-leave-active {
  transition: 0.35s;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

/*****************/
//Quiz input list
/*****************/

.QuizInputList {
  display: flex;
  flex-direction: column;

  .QuizInput:not(:last-child) {
    margin-bottom: 15px;
  }
}

.quiz-bilan-activity,
.intro-quiz-bilan-activity {
  opacity: 1;
  visibility: visible;
  overflow: hidden;
  max-height: 6000px;
  transition: opacity 0.7s ease-in-out, max-height 0.7s ease-in-out,
    visibility 0s linear;

  &[aria-hidden="true"] {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.7s ease-in-out, top 0.7s ease-in-out,
      max-height 0.7s ease-in-out, visibility 0s linear 0.7s;
  }

  .QuizInput-checkbox,
  .QuizInput-radio {
    .QuizInput-label {
      text-align: center;
      padding: 10px;
      line-height: initial;
      font-size: 1.6rem;
      padding: 7px 20px;
    }

    @media (mq.$desktop) {
      font-size: 1.4rem;
      padding: 10px 0;
    }
  }
}

.quiz-bilan-activity {
  transition-delay: 0.7s;
}

.start-quiz-container {
  text-align: center;
  padding-bottom: 20px;
}

.Quiz-name {
  text-align: center;

  &.title2 {
    font-size: 2.3rem;

    @media (mq.$desktop) {
      font-size: 3rem;
    }
  }
}

/*****************/
//Quiz Loader
/*****************/

.Quiz-loader {
  min-height: 1000px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: linear-gradient(colors.$lighterGrey 150px, transparent 0),
    // title
      linear-gradient(colors.$lighterGrey 200px, transparent 0),
    // image
      linear-gradient(colors.$lighterGrey 50px, transparent 0),
    // secondary title
      linear-gradient(colors.$lighterGrey 100px, transparent 0),
    // text
      linear-gradient(colors.$lighterGrey 50px, transparent 0),
    // tertiary title
      linear-gradient(colors.$lighterGrey 100px, transparent 0),
    // text
      linear-gradient(colors.$lighterGrey 50px, transparent 0),
    // button
      linear-gradient(transparent 100%, transparent 0); // button
  background-size: 900px 150px,
    // title
      200px 200px,
    // image
      700px 50px,
    // secondary title
      1200px 100px,
    // text
      700px 50px,
    // tertiary title
      1200px 100px,
    // text
      500px 50px,
    // button
      100% 100%; // button
  background-position: center 30px,
    // Type
      center 220px,
    // image
      center 460px,
    // secondary title
      center 550px,
    // text
      center 680px,
    // tertiary title
      center 770px,
    // text
      center 990px,
    // button
      0 0; // BG

  animation: 1.5s ease 0s infinite alternate opacity;

  &[aria-hidden="true"] {
    display: none;
  }
}

@keyframes opacity {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}
