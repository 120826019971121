/*! QuizQuestionActivity scss component */

@use 'utilities/mq';
@use 'utilities/colors';

.QuizQuestionActivity-QuizInputList {
  display: flex;
  justify-content: center;
}

.QuizQuestionActivity {
  .QuizInput-radio {
    &:not(:last-child) {
      margin-right: 15px;
    }

    input:checked + label {
      background: colors.$black;
      color: #fff;
    }

    label {
      min-width: 60px;
      border: 1px solid colors.$black;
      border-radius: 50px;
      text-align: center;

      &::after,
      &::before {
        display: none;
      }
    }
  }

  .Quiz-Cta {
    @media (mq.$from-tablet) {
      text-align: left;
    }
  }

  .QuizQuote-separator {
    max-width: 700px;
    margin: 40px auto;

    @media (mq.$from-tablet) {
      margin: 60px auto;
    }
  }

  .QuizQuote-quote {
    max-width: 700px;
    margin: 0 auto;
  }

  .QuizHeader {
    max-width: 700px;
    margin: 0 auto;
  }
}

.QuizQuestionActivity-footer {
  margin-top: 20px;

  .Quiz-Cta {
    .Cta {
      border: 1px solid transparent;

      &:hover {
        border: 1px solid colors.$lighterGrey;
      }
    }

    @media (mq.$from-tablet) {
      text-align: center;
    }
  }
}
