/*! Breadcrumbs Component */
@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/pageTheme'as theme;

.Breadcrumbs {
  display: inline-block;
  margin: 0 auto;
  padding: 5px;
  font-size: 0; // Needed to remove li default space when inline

  @media print {
    display: none;
  }

  @media (mq.$phone) {
    display: none;
  }

  ul {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  li {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 1.4rem;
    font-weight: 600;

    @include theme.themify {
      box-shadow: 0 0 0 4px theme.$color-light;
      background-color: theme.$color-light;
    }

    &:not(:last-child)::after {
      content: ">";
      margin: 0 10px;
    }
  }

  a {
    border-bottom: 2px solid #000;
    color: inherit;
    text-decoration: none;
  }
}

@each $theme, $colors in theme.$activityTheme-colors {
  [data-activity="#{$theme}"] {
    .Breadcrumbs {
      li {
        background-color: map-get($colors, "light");
        box-shadow: 0 0 0 4px map-get($colors, "light");
      }
    }
  }
}
