/*! FilterTags scss component */

@use 'utilities/colors';
@use 'utilities/variables';
@use 'utilities/mq';

.FilterTags {
  display: flex;
  justify-content: center;
  margin-block: -4rem 2rem;
  gap: 0.7rem;
  
  @media (mq.$from-tablet) {
    gap: 1.4rem;
    margin-block: -2rem 4rem;
  }

  @media (mq.$desktop) {
    margin-block: -2rem 6rem;
  }
}

.FilterTags-tag {
  display: flex;
  align-items: center;
  justify-content: center;

  .Cta {
    font-size: 1.3rem;
    line-height: 1.8rem;
    padding: 0.8rem 1.2rem;
    border-radius: 1.7rem;
    margin-bottom: 0;
  
    @media (mq.$desktop) {
      font-size: 1.4rem;
      line-height: normal;
      padding: 0.5rem 2rem;
      border-radius: 2.5rem;
    }

    &:hover, #{variables.$keyboard} &:focus {
      color: #fff;
    }
  }

  &[data-FilterTags-active=true] {
    color: #fff;
  }
}

.FilterTags-all {
  .Cta {
    border-color: colors.$black;

    &.Cta--outline{
      &:hover{
        background-color: colors.$black;
      }
      #{variables.$keyboard} &:focus{
        background-color: colors.$black;
        box-shadow:0 0 0 2px #fff, 0 0 0 4px colors.$black;
      }
    }
  }

  &[data-FilterTags-active=true] {
    .Cta{
      background-color: colors.$black;
    }
  }
}

.FilterTags-advices {
  .Cta {
    border-color: colors.$orange;

    &.Cta--outline{
      &:hover{
        background-color: colors.$orange;
      }
      #{variables.$keyboard} &:focus{
        background-color: colors.$orange;
        box-shadow:0 0 0 2px #fff, 0 0 0 4px colors.$orange;
      }
    }

  }

  &[data-FilterTags-active=true] {
    .Cta{
      background-color: colors.$orange;
    }
  }
}

.FilterTags-recipes {
  .Cta {
    border-color: colors.$red;

    &.Cta--outline{
      &:hover{
        background-color: colors.$red;
      }
      #{variables.$keyboard} &:focus{
        background-color: colors.$red;
        box-shadow:0 0 0 2px #fff, 0 0 0 4px colors.$red;
      }
    }
  }

  &[data-FilterTags-active=true] {
    .Cta{
      background-color: colors.$red;
    }
  }
}

.FilterTags-activities {
  .Cta {
    border-color: colors.$blue;

    &.Cta--outline{
      &:hover{
        background-color: colors.$blue;
      }
      #{variables.$keyboard} &:focus{
        background-color: colors.$blue;
        box-shadow:0 0 0 2px #fff, 0 0 0 4px colors.$blue;
      }
    }
  }

  &[data-FilterTags-active=true] {
    .Cta{
      background-color: colors.$blue;
    }
  }
}