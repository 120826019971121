/*! SectionHead Component */

@use 'utilities/mq';

.SectionHead {
  max-width: 40em;
  margin: 1em auto 2em;
  text-align: center;
  font-size: 1.6rem;

  @media (mq.$from-tablet) {
    width: 80%;
    font-size: 2rem;
  }

  &::after {
    content: "";
    display: block;
    width: 120px;
    max-width: 30%;
    margin: 1em auto 0;
    border-bottom: solid .35em black;
  }
}

.SectionHead-title {
  font-weight: 700;
  font-size: 150%;
  line-height: 1.2;
}

.SectionHead-subtitle {
  margin-top: .35em;
  opacity: 0.7;
  line-height: 1.3;
  font-size: 115%;
}