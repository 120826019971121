/*! UserSettings scss component */

@use 'utilities/colors';
@use 'utilities/mq';

.UserSettings {
  text-align: center;

  .Parameters-image {
    width: 200px;
    height: auto;
  }

  .title2.titleBackground {
    font-size: 2.3rem;
    margin-block: 1.5rem;

    @media (mq.$desktop) {
      font-size: 2.4rem;
      margin-block: 3rem 2rem;
    }
  }

  .QuizResults-info {
    font-size: 1.6rem;
  }

  &.setting-container {
    max-width: 622px;
    margin-inline: auto;
  }
}

.UserSettings-settings {
  padding: 5rem 0;
  border-bottom: 1px solid colors.$lightGrey;

  max-width: 840px;
  margin-inline: auto;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
  }

  .QuizResults {
    text-align: center;

    .QuizResults-ctas {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;

      @media (mq.$desktop) {
        flex-direction: row;
      }
    }
  }
}

.bottom-settings-text {
  text-align: center;
  margin-block: 2rem 2rem;
  font-size: 1.6rem;

  @media (mq.$desktop) {
    margin-block: 2rem 3rem;
  }
}

.bottom-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-block-end: 4rem;

  @media (mq.$desktop) {
    flex-direction: row;
    margin-block-end: 10rem;
  }
}
