/* FooterLegal scss component */

@use 'utilities/mq';
@use 'utilities/colors';

.FooterLegal {
  background: #444;

  .container {
    background: none;
  }

  .container-inner {
    padding: 4.5rem 7rem;
    @media (mq.$desktop) {
      padding: 3.5rem 0;
    }
  }
}

.FooterLegal-links {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (mq.$from-tablet) {
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    flex-direction: row;
  }
}

.FooterLegal-link {
  font-family: "HelveticaNeue";
  position: relative;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    padding-right: 10px;
  }

  &:not(:first-child) {
    @media (mq.$from-tablet) {
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -2px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #fff;
        transform: translateY(-50%);
      }
    }
  }

  @media (mq.$desktop) {
    font-size: 1.6rem;
    font-weight: 600;
  }
}
