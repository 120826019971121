/*! GridCard */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';

.GridCard {
  .Card--rubrique,
  .CardConseil,
  .Card {
    @media (mq.$phone) {
      margin-bottom: 10px;
    }
  }

  @media (mq.$desktop) {
    max-width: 952px;
    margin: auto;
    margin-block-end: 3rem;
  }

  .Card--rubrique:last-child,
  .CardConseil:last-child,
  .Card:last-child {
    @media (mq.$phone) {
      margin-bottom: 0;
    }
  }
}

.GridCard-button--filter {
  margin-bottom: 2rem;

  @media (mq.$desktop) {
    margin-bottom: 4rem;
  }

  @media (mq.$phone) {
    padding-bottom: 10px;
    margin-top: -30px;
    margin-bottom: 10px !important;
  }
}

.GridCard--extraPadding {
  .GridCard-button {
    @media (mq.$from-tablet) {
      margin-bottom: 60px;
    }
  }
}

[data-activity-gridcard="low"] {
  .Card-Intensity--high,
  .Card-Intensity--medium,
  .Card-Intensity--low {
    background-color: colors.$lowBlue;

    .Card-informations span,
    .Card-type span {
      background-color: colors.$lowBlueLight;
      box-shadow: 0.7rem 0 0 colors.$lowBlueLight,
        -0.7rem 0 0 colors.$lowBlueLight;
    }
  }
}

[data-activity-gridcard="medium"] {
  .Card-Intensity--low,
  .Card-Intensity--high,
  .Card-Intensity--medium {
    background-color: colors.$mediumBlue;

    .Card-informations span,
    .Card-type span {
      background-color: colors.$mediumBlueLight;
      box-shadow: 0.7rem 0 0 colors.$mediumBlueLight,
        -0.7rem 0 0 colors.$mediumBlueLight;
    }
  }
}

[data-activity-gridcard="high"] {
  .Card-Intensity--low,
  .Card-Intensity--medium,
  .Card-Intensity--high {
    background-color: colors.$highBlue;

    .Card-informations span,
    .Card-type span {
      background-color: colors.$highBlueLight;
      box-shadow: 0.7rem 0 0 colors.$highBlueLight,
        -0.7rem 0 0 colors.$highBlueLight;
    }
  }
}

.GridCard-top,
.GridCard-bottom {
  text-align: center;
}

.GridCard-top {
  margin-bottom: 2rem;

  @media (mq.$desktop) {
    margin-bottom: 4rem;
  }
}

.GridCard-top--actions {
  @media (mq.$desktop) {
    margin-bottom: 2rem;
  }
}

.GridCard-marginBottom {
  .GridCard-bottom {
    @media (mq.$from-tablet) {
      margin-bottom: 4rem;
    }
  }
}

.GridCard-bottom {
  margin-top: 3rem;

  @media (mq.$desktop) {
    margin-top: 2rem;
  }

  .Cta {
    margin-bottom: 0;

    border-width: 1px;
    padding: 7px 20px;
    font-size: 1.6rem;

    span {
      line-height: normal;
    }

    @media (mq.$desktop) {
      font-size: 1.4rem;
      padding: 10px 20px;
    }
  }
}

.GridCard-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -3rem;
  justify-content: flex-start;

  @media (mq.$desktop) {
    margin-left: -2.9rem;
  }
}

.GridCard-loading {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &[aria-hidden="true"] {
    display: none;
  }
}

.GridCard-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  .Cta:not(:last-child) {
    margin-right: 20px;
  }

  .Cta {
    &[data-quizresults-gridcard-button="true"] {
      background-color: colors.$greenDark;
    }
  }

  @media (mq.$desktop) {
    margin-bottom: 4rem;
  }
}

.Gridcard-emptyText {
  text-align: center;
  margin: 0 auto;
  padding-left: 40px; // because in a gridCard with margin-left: -4rem
}

.GridCard-button--seeMore {
  .Cta--dark {
    &:hover,
    #{variables.$keyboard} &:focus {
      border: 1px solid colors.$lightGrey;
    }

    @media (mq.$phone) {
      &:hover,
      #{variables.$keyboard} &:focus {
        background-color: colors.$black;
        border: 1px solid colors.$black;
        color: #f0f0f0;

        .icon {
          color: #fff;
        }
      }
    }
  }
}
