/*! ArticleDates css component */

@use 'utilities/mq';

.article-dates {
  margin-block-end: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.1rem;

  font-size: 1.6rem;
  color: #707079;

  @media(mq.$desktop) {
    margin-block-end: 6rem;
    flex-direction: row;
    gap: 0.9rem;
    align-items: center;

    font-size: 1.4rem;
  }

  &__text {
    font-weight: 700;
  }

  &__date {
    font-weight: 400;
  }

  &__divider {
    display: none;

    @media(mq.$desktop) {
      display: grid;
      place-items: center;
      height: 0.6rem;
      width: 0.6rem;
      border-radius: 50%;
      background-color: #44AB46;
    }
  }

}