/*! QuizCta scss components */

@use 'utilities/mq';

.QuizCta-previousButton {
  margin-bottom: 40px;
  
  @media (mq.$phone) {
    z-index: 2;
    position: absolute;
    left: 20px;

    .Cta {
      padding: 10px;
      border: 1px solid #CECECE;
      line-height: 1;
      overflow: hidden;
      box-shadow: none;
    }

    .icon {
      margin: 0;
    }

    .Cta-label {
      display: none;
    }
  }
}