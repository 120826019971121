/*! Footer scss component*/

@use 'utilities/mq';
@use 'utilities/colors';

.Footer {
  .container-inner {
    padding: 0;
    @media (mq.$from-tablet) {
      padding: 8.5rem 1rem 0;
    }
    @media (min-width: 1300px) {
      padding: 8.5rem 0 0;
    }
  }
}

.Footer--spacing {
  @media (mq.$phone) {
    margin-top: 60px;
  }
}

.FooterBreadcrumbs-container {
  .container-inner {
    @media (mq.$from-tablet) {
      padding: 0;
    }
  }
}

.FooterBreadcrumbs {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (mq.$phone) {
    padding: 60px 0;
  }

  .Breadcrumbs {
    @media (mq.$from-tablet) {
      display: none;
    }

    @media (mq.$phone) {
      display: inline-block;
      margin-bottom: 60px;
      text-align: center;
    }

    li {
      margin-bottom: 10px;
      background-color: colors.$greenLight;
      box-shadow: 0 0 0 4px colors.$greenLight;
    }
  }
}
