/*! Filter list scss component */

@use 'utilities/colors';
@use 'utilities/mq';
@use 'utilities/variables';
@use 'utilities/functions';
@use 'utilities/pageTheme'as theme;

.Filterlist {
  margin-bottom: 30px;

  @media (mq.$from-tablet) {
    margin-bottom: 40px;
  }
}

.Filterlist-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media (mq.$from-tablet) {
    margin-bottom: 40px;
  }
}

.Filterlist-line {
  width: 100%;
  height: 2px;
  background: colors.$lighterGrey;
}

.Filterlist-title {
  flex-shrink: 0;
  padding: 0 30px;
  font-size: 2.4rem;
  font-weight: 700;

  @media (mq.$from-tablet) {
    font-size: 3.2rem;
  }
}

.Filterlist-filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.Filterlist-filter {
  margin-bottom: 1rem;
  margin-right: 10px;
  margin-left: 10px;
}

.Filterlist-label {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 10px;
  transition: 0.15s;

  @media (mq.$from-tablet) {
    padding: 15px;
  }

  @include theme.themify {
    border: 2px solid theme.$color-base;
  }

}

.Filterlist-input {
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;

  &:checked+label {
    @include theme.themify {
      background-color: theme.$color-base;
    }
  }

  #{variables.$keyboard} &:focus+label {
    @include theme.themify {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px theme.$color-base;
    }
  }
}

.Filterlist--custom {
  padding: 20px 10px;
  border-radius: 10px;
  background-color: #f5f5f5;

  .Filterlist-filters {
    @media(mq.$phone) {
      flex-direction: column;
    }
  }

  .Filterlist-filter {
    @media(mq.$from-tablet) {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .Filterlist-header {
    justify-content: center;
  }

  .Filterlist-line {
    display: none;
  }

  .Filterlist-input:checked+label {
    &:before {
      background-color: #fff;
    }

    .icon {
      transform: translateY(-50%) rotate(90deg);

      @include theme.themify {
        color: theme.$color-base;
      }
    }
  }

  .Filterlist-label {
    padding: 8px 15px 8px 50px;
    border-radius: 40px;

    .icon {
      position: absolute;
      left: 8px;
      top: 50%;
      transition: transform .15s, color 0.15s;
      transform-origin: center;
      transform: translateY(-50%) rotate(45deg);
    }

    &::before {
      content: '';
      position: absolute;
      left: -3px;
      top: 50%;
      width: 42px;
      height: 42px;
      padding: 10px;
      border-radius: 50%;
      transform: translateY(-50%);
      transition: background-color 0.15s;

      @include theme.themify {
        background-color: theme.$color-base;
      }
    }
  }
}