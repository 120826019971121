/*! QuizEnd scss component */

@use 'utilities/mq';

.QuizEnd {
  @media (max-height: 700px) {
    padding-bottom: 100px;
  }
}

.QuizEnd-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  margin: 0 auto;
}

.QuizEnd-title {
  text-align: center;

  &.title2 {
    font-size: 2.3rem;

    @media (mq.$desktop) {
      font-size: 2.4rem;
    }
  }
}

.QuizEnd-image {
  display: block;
  margin: 0 auto 4rem;
  max-width: 500px;
  width: 100%;

  @media (mq.$phone) {
    max-width: 100%;
    width: auto;
    max-height: 130px;
    margin: 0 auto 3rem;
  }
}

.QuizEnd-text,
.QuizEnd-connectionText {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 700;
  font-size: 1.6rem;
}

.QuizEnd-text {
  @media (mq.$phone) {
    margin-top: 0;
  }
}

.QuizEnd--activity {
  padding-bottom: 60px;
}

.QuizEnd-CtaContainer {
  margin-top: 20px;
}
