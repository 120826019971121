// -----------------------------------------------------------------------------
// This file contains containers that defined the layout
// -----------------------------------------------------------------------------

@use 'utilities/mq';
@use 'utilities/colors';

.container {
  position: relative;
  z-index: 1;
  max-height: 100%;
  background-color: #fff;
  transition: .3s;

  &[data-quiz-gridcard=hidden] {
    max-height: 0;
    overflow: hidden;
  }
}

.container-inner {
  max-width: 100%;
  min-height: 0;
  margin: 0 auto;
  padding: 60px 20px;
  transition: padding .3s;
  
  >div {
    .Ressources,
    .Tags {
      &:last-child {
        border-bottom: none;
      }
    }
  }
  
  @media(mq.$from-tablet) {
    padding: 60px 20px;
  }
}

.container-inner--relative {
  position: relative;
}

.container--overflowHidden {
  overflow: hidden;
}

// Exist because when container has radius and z-index:1 it can cause issue in particular case
.container--indexTop {
  z-index: 2;
}

.container--pattern-grudge,
.container--pattern {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 400px;
  opacity: .15;

  @media (mq.$from-tablet) {
    background-position: top left, bottom right;
    background-size: 400px, 400px;
  }

  &~.container-inner {
    position: relative;
    z-index: 1;
  }
}

.container--pattern-grudge {
  opacity: 1;
  background-position: center;
  background-size: cover;
}

// Handle the color of the container
.container--grey {
  background-color: #f5f5f5;
}

.container--green {
  background-color: colors.$green;
}

.container--overflowHidden {
  overflow: hidden;
}

.container--dark {
  background-color: colors.$black;
  color: #fff;
}

// Handle the style of the container (straight || radius)
.container--radiusVertical {
  margin-top: -60px;
  margin-bottom: -60px;
  border-radius: 35px;
  box-shadow: 0 -8px 20px 0 rgba(0, 0, 0, 0.09);

  @media (mq.$from-tablet) {
    border-radius: 60px;
  }
}

.container--radiusTop {
  margin-top: -60px;
  border-radius: 35px 35px 0 0;
  box-shadow: 0 -8px 20px 0 rgba(0, 0, 0, 0.09);

  @media (mq.$from-tablet) {
    border-radius: 60px 60px 0 0;
  }
}

.container--radiusBottom {
  margin-bottom: -60px;
  padding: 40px 0 80px;
  border-radius: 0 0 35px 35px;
  box-shadow: 0 -8px 20px 0 rgba(0, 0, 0, 0.09);

  @media (mq.$from-tablet) {
    border-radius: 0 0 60px 60px;
  }
}

//handle the spacing of the container and his direct child
.container--radiusTopInset {
  position: absolute;
  top: -2px;
  left: 0;
  height: 60px;
  width: 100%;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;

  @media (mq.$from-tablet) {
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
  }
}

.container-inner .container--noPaddingTop,
.container .container--noPaddingTop {
  padding-top: 0;

  @media (mq.$from-tablet) {
    padding-top: 0;
  }
}

.container .container--noPaddingBottom,
.container-inner .container--noPaddingBottom {
  padding-bottom: 5px; // i don't know why it's cut the last element (button) so i let 5 px instead of 0

  @media (mq.$from-tablet) {
    padding-bottom: 5px;
  }
}

// Handle the size of the container
.container-inner--large {
  width: mq.$layout-large;
}

.container-inner--medium {
  width: mq.$layout-medium;
}

.container-inner--small {
  width: mq.$layout-small;

  @media (mq.$phone) {
    width: 100%;
  }
}

// Handle the child layout positionnement
.container-inner--twocols {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  >*:nth-child(1) {
    width: 60%;

    @media (mq.$upto-tablet) {
      width: 100%;
    }
  }

  >*:nth-child(2) {
    width: 30%;

    @media (mq.$upto-tablet) {
      width: 100%;
    }
  }
}

.container-inner--reverse {
  flex-direction: column-reverse;

  @media (mq.$desktop) {
    flex-direction: row;
  }
}

// Handle the child layout positionnement
.container-text {
  max-width: 730px;
}

.container--noRadius {
  z-index: 0;
  padding-top: 40px;

  @media (mq.$from-tablet) {
    padding-top: 60px;
  }
}

.Recommandation-containerBlockquote {
  @media (mq.$phone) {
    padding-top: 55px;
    padding-bottom: 30px;
  }
}

.Recommandation-containerContent {
  @media (mq.$phone) {
    padding-top: 30px;
  }
}