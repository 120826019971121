/*! Seemore scss component */

@use 'utilities/mq';
@use 'utilities/variables';
@use 'utilities/colors';

.SeeMore--radiusAfter{
  padding-bottom:60px;
}

.SeeMore-text{
  margin-bottom:2rem;
  overflow: hidden;
  text-align: center;
  transition: 1s;
}

.SeeMore-button{
  margin: 0 auto;
  border-bottom: 2px solid colors.$black;
  font-weight: 700;
  transition: .15s;
  
  #{variables.$keyboard} &:focus,
  &:hover{
    border-color: colors.$green;
  }
}