/*! GlossaryDrawer css component */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';
@use 'utilities/mixins';
@use 'utilities/pageTheme'as theme;



.GlossaryDrawer-container {
  max-height: 190px;
  overflow-y: auto;
  
  @media (mq.$from-tablet) {
    max-height: 110px;
  }
  
  @media (mq.$tablet) {
    max-height: 170px;
  }
  
  @media (mq.$desktop){
    @include mixins.add-scrollbar-style;
  }
}

.GlossaryDrawer-content {
  padding-top: 10px;

  @media (mq.$from-tablet) {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 160px;
  }
}

.GlossaryDrawer-header {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
  padding-right: 60px;

  @media (mq.$from-tablet) {
    margin-bottom: 0;
  }
}

.GlossaryDrawer-image {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  margin-right: 20px;

  @media (mq.$from-tablet) {
    width: 120px;
    height: 120px;
    position: absolute;
    left: -140px;
    top: 0;
  }
}

.GlossaryDrawer-title {
  font-size: 2.5rem;

  @media (mq.$from-tablet) {
    font-size: 3.2rem;
    margin-bottom: 1.5rem;
  }
}


.GlossaryDrawer-resources {
  margin-top: 1rem;

  @media (mq.$from-tablet) {
    margin-top: 2rem;
  }

  .Ressources-link,
  .Ressources-media {
    display: flex;
    margin-bottom: 2rem;
    font-size: 1.4rem;

    @media (mq.$from-tablet) {
      font-size: 1.6rem;
    }
  }
}


.GlossaryDrawer-text {
  font-size: 1.8rem;
}

.GlossaryDrawer--inPage {
  color: colors.$black;
  border-bottom: 3px solid colors.$greenDark;
  outline: none;
  transition: text-decoration, color;
  transition-duration: .15s;
  
  &[aria-expanded=true] {
    color: colors.$greenDark;
    text-decoration-thickness: 5px;
    text-decoration-color: colors.$greenDark;
  }
  
  &:hover, #{variables.$keyboard} &:focus {
    color: colors.$greenDark;
  }
}