/*! searchresults scss component */

@use 'utilities/colors';
@use 'utilities/mq';

.SearchResults {

  .Seasonfilter-filters {
    padding-bottom: 0;
  }

  .Bookmark & {
    @media (mq.$phone) {
      .SearchResults-title {
        margin-bottom:0;
      }

      .SearchResults-spacer {
        margin: 4rem 0;
      } 
    }
  }

  .Seasonfilter {
    padding: 0;

    .Seasonfilter-container:first-child,
    .Seasonfilter-container--withLine:before {
      display: none;
    }
  }
}

.SearchResults-title {
  margin-bottom: 3rem;
  text-align: center;
}

.SearchResults-filtersContainer {
  text-align: center;
  padding-bottom: 30px;
  
  @media (mq.$from-tablet) {
    padding-bottom: 70px;
  }
  
  .title2 {
    @media (mq.$phone) {
      font-size: 3rem;
      margin-top: -20px;
    }
  }
}

.SearchResults-filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (mq.$from-tablet) {
    flex-direction: row;
  }
}

.SearchResults-filters--noresults {
  flex-direction: column;

  .SearchResults-title {
    @media (mq.$phone) {
      margin-bottom: 0;
    }
  }
}

.SearchResults-filter--text {
  display: flex;
  align-items: center;

  span:first-child {
    display: block;
    margin-right: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }
}


.SearchResults-filter {

  &:not(.Cta--underline) {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
    margin: 0 0 20px;
    border: none;

    @media (mq.$from-tablet) {
      width: unset;
      margin: 0 20px 20px;
    }

    .Cta-label {
      @media (mq.$phone) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    &:hover {
      background: colors.$green;
      color: colors.$black;
    }
  }

  &.Cta--underline {
    margin: 0 auto;
    text-align: center;
  }
}

.SearchResults-filter--active {
  &:not(.Cta--underline) {
    background: colors.$green;
    color: colors.$black;
    pointer-events: none;
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.4);
  }
}

.SearchResults-text {
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;

  @media (mq.$from-tablet) {
    font-size: 3rem;
  }
}

.SearchResults-sitemapLink {
  margin-top: 3rem;
}

.SearchResults-spacer {
  height: 1px;
  width: 100%;
  margin: 6rem 0;
  background-color: rgba(0, 0, 0, 0.2);
  
  @media (mq.$phone) {
    margin: 2rem 0;
  }
}