






































































































































































.SPFPlayer-player {
  position: relative;

  &.is-playing {
    .SPFPlayer-playerButton {
      opacity: 0;
      visibility: hidden;
      transition:
        opacity .3s ease-in-out,
        visibility 0s linear .3s;
    }
  }
}

.SPFPlayer-playerButton {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  transition:
    opacity .5s ease-in-out,
    visibility 0s linear;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // svg {
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   width: 75px;
  //   height: 75px;
  //   transform: translate(-50%, -50%);
  // }
}

.SPFPlayer-mediaPlayer {
  display: block;
}
