/* TitleWithSubtitle */

@use 'utilities/mq';

.TitleWithSubtitle {
  h2,
  h3, 
  h4 {
    margin-bottom: 0.5rem;
  }
}

.TitleWithSubtitle-subtitle {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 1.6rem;

  @media(mq.$from-tablet) {
    font-size: 2.4rem;
  }
}