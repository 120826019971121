/*! QuizStepper scss component */

@use 'utilities/colors';
@use 'utilities/mq';

.QuizStepper {
  display: flex;
  margin-bottom: 60px;
  margin-top: 40px;

  @media (mq.$phone) {
    margin-bottom: 30px;
    margin-top: 0;
  }
}

.QuizStepper-step {
  position: relative;
  flex-basis: 100%;
  height: 10px;
  border-radius: 10px;
  background: #d8d8d8;
  transition: .3s;

  &:first-child {
    &::before {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: -15px;
    width: 20px;
    height: 10px;
    background: #d8d8d8;
    transition: .3s;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    right: 2px;
    top: 50%;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    transform: translateY(-50%);
    background: rgba(colors.$black, 0.7);
    transition: .3s;
  }
}

.QuizStepper-step--active {

  &::after {
    background-color: #fff;
  }

  &:first-child {
    .QuizStepper-stepActive {
      left: 0;
      max-width: 100%;
    }
  }

  .QuizStepper-stepActive {
    width: calc(100% + 15px);
  }
}

.QuizStepper-stepActive {
  position: relative;
  left: -15px;
  width: 0;
  height: 10px;
  border-radius: 10px;
  background: colors.$green;
  transition: .4s;
}