@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';

.LandingCards {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title2 {
    margin-bottom: 60px;
  }
}

.LandingCards {
  .Card--rubrique,
  .CardConseil,
  .Card {
    @media (mq.$phone) {
      margin-bottom: 10px;
    }
  }

  @media (mq.$desktop) {
    max-width: 952px;
    margin: auto;
    margin-block-end: 3rem;
  }

  .Card--rubrique:last-child,
  .CardConseil:last-child,
  .Card:last-child {
    @media (mq.$phone) {
      margin-bottom: 0;
    }
  }
}

.LandingCards-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -3rem;
  justify-content: flex-start;

  @media (mq.$desktop) {
    margin-left: -2.9rem;
  }
}

.LandingCards {
  .Card--rubrique,
  .CardConseil,
  .Card {
    @media (mq.$phone) {
      margin-bottom: 10px;
    }
  }
}

.LandingCards-button--seeMore {
  margin-top: 2rem;

  @media (mq.$desktop) {
    margin-bottom: 6rem;
  }

  .Cta--dark {
    @media (mq.$phone) {
      &:hover,
      #{variables.$keyboard} &:focus {
        background-color: colors.$black;
        border: 2px solid colors.$black;
        color: #f0f0f0;

        .icon {
          color: #fff;
        }
      }
    }
  }
}

.LandingCards-content--moreCards {
  display: none;
  margin-top: 20px;

  @media (mq.$desktop) {
    margin-top: 0;
  }
}