// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@use 'utilities/functions' as func;

/*! HelveticaNeue */
// http://www.abstractfonts.com/search/helvetica.woff

$helvetica-neue: (
  Regular: 400 normal,
  Medium: 600 normal,
  Bold: 700 normal,
);

$marianne: (
  Thin: 100 normal,
  Light: 300 normal,
  Regular: 400 normal,
  Medium: 600 normal,
  Bold: 700 normal,
  ExtraBold: 900 normal,
);

@each $variant-name, $variant-data in $helvetica-neue {
  $base: 'HelveticaNeue/HelveticaNeue-' + $variant-name;
  @font-face {
    font-family: "HelveticaNeue";
    font-style: nth($variant-data, 2);
    font-weight: nth($variant-data, 1);
    src: func.font(($base + ".woff2")) format('woff2'),
         func.font(($base + ".woff")) format('woff')
  }
}

@each $variant-name, $variant-data in $marianne {
  $base: 'Marianne/Marianne-' + $variant-name;
  @font-face {
    font-family: "Marianne";
    font-style: nth($variant-data, 2);
    font-weight: nth($variant-data, 1);
    src: func.font(($base + ".woff2")) format('woff2'),
         func.font(($base + ".woff")) format('woff')
  }
}