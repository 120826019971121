/*! Ressources css component */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';
@use 'utilities/pageTheme'as theme;


.Ressources {
  padding: 30px 0;
  margin-bottom: 50px;
  border-bottom: 1px solid colors.$lightGrey;
}

.Ressources-title {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 2rem;

  span {
    background-color: colors.$greenLight;
    box-shadow: 1.4rem 0 0 colors.$greenLight, -1.4rem 0 0 colors.$greenLight;
  }
}

.Ressources-text {
  margin-bottom: 2rem;
}

.Ressources-link,
.Ressources-media {
  display: inline-flex;
  margin-bottom: 2rem;
  font-weight: 600;
  color: currentColor;

  span {
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 2px;
    transition: 0.15s;

    @media (mq.$from-tablet) {
      text-decoration-thickness: 2px;
    }
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    span {
      @include theme.themify {
        text-decoration-color: theme.$color-base;
      }
    }
  }
}

.Ressources-link {
  .icon {
    position: relative;
    top: 5px;
    width: 10px;
    height: 10px;
    margin-right: 1rem;

    @media (mq.$from-tablet) {
      top: 6px;
    }
  }
}

.Ressources-media {
  align-items: center;
  cursor: pointer;

  .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;

    @include theme.themify {
      color: theme.$color-base;
    }

    @media (mq.$from-tablet) {
      width: 4rem;
      height: 4rem;
    }
  }
}