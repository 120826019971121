/*! Delay */

@use 'utilities/mq';
@use 'utilities/pageTheme' as theme;

.Delay {
  margin-top: 5px;
  font-weight: 600;

  @include theme.themify {
    color: theme.$color-dark;
  };

  .icon {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    vertical-align: sub;

    @media(mq.$phone) {
      margin-bottom: 3px;
    }
  }
}

@each $theme,
$colors in theme.$activityTheme-colors {
  [data-activity="#{$theme}"]{
    .Delay {
      color: map-get($colors, 'dark');
    }
  }
}