/* FoodKeynumbers scss component */

@use "utilities/mq";

.FoodKeynumbers-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.FoodKeynumbers-element {
  width: 50%;
  width: calc(50% - 50px);

  @media (mq.$phone) {
    max-width: 100%;
    width: 100%
  }
  
  &:not(:last-child) {
    @media (mq.$from-tablet) {
      margin-right: 5rem;
    }
  }
}

.FoodKeynumbers-element--margin {
  &:not(:last-child) {
    @media (mq.$phone) {
      margin-bottom: 20px;
    }
  }
}

.FoodKeynumbers-number {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;

  @media (mq.$from-tablet) {
    font-size: 2.8rem;
  }
}

.FoodKeynumbers-caption {
  font-weight: 700;
}