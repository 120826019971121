/*! Navigation TopBar */

@use 'utilities/mq';


.NavigationTopBar {
  position: relative;
  flex-shrink: 0;
  z-index: 100;
  overflow: hidden;
  border-bottom-left-radius: 35px;
  background-color: #fff;
  transition: 0.5s;

  &[data-navigation=open] {
    border-bottom-left-radius: 0;

    .NavigationTopBar-container {
      padding: 0;
    }

    .NavigationTopBar-links {
      opacity: 0;
      padding: 15px 0;
    }
  }

  @media (mq.$desktop-navigation) {
    border-bottom-left-radius: 0;
  }

  @media (mq.$phone) {
    display: flex;
    width: 195px;
    max-height: 80px;

    &[data-navigation=open] {
      width: 0;
    }
  }

  @media (mq.$desktop-navigation) {
    border-bottom: 1px solid #f0f0f0;
  }
}

.NavigationTopBar--mobileHided {
  @media (mq.$phone) {
    display: none;
  }
}

.NavigationTopBar-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 15px 30px;
  transition: 0.5s;

  @media (mq.$phone) {
    padding: 5px 30px;
  }

  @media (mq.$phoneXS) {
    padding: 5px 15px;
  }
}

.NavigationTopBar-links {
  position: relative;
  display: block;
  height: 50px;
  box-sizing: content-box;
  overflow: hidden;
  opacity: 1;
  transition: 0.5s;

  .icon {
    height: 100%;
    width: 100%;
  }

  @media (mq.$phone) {
    height: 40px;
  }

  &:not(:last-child) {
    width: 50px;  
    padding-right: 15px;
    
    @media (mq.$phoneXS) {
      padding-right: 0px;
    }
  }
  
  &:not(:first-child) {
    .icon {
      width: 100px;
    }

    @media (mq.$from-tablet) {
      padding-left: 15px;
    }
    

    @media (mq.$phone) {
      width: 100px;
      
      .icon {
        width: 70px;
      }
    }
  }
}