/*! QuizSelect scss component */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';


.QuizInput-selectLabel {
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

.QuizInput-select {
  @media (mq.$phone) {
    width: calc(100% - 8px);
  }
}

.QuizInput-selectInput {
  min-width: 100%;
  padding: 10px;
  border: 1px solid colors.$black;
  outline: none;
  border-radius: 10px;
  font-size: 1.6rem;
  background-color: #fff;

  @media (mq.$from-tablet) {
    min-width: 300px;
  }

  #{variables.$keyboard} &:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px colors.$black;
  }
}