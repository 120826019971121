/*! SPFPlayer css component */

@use 'utilities/colors';
@use 'utilities/mq';
@use 'utilities/variables';
@use 'utilities/pageTheme'as theme;

.SPFPlayer {
  position: relative;
  margin-bottom: 2rem;

  video {
    width: 100%;
  }
}

.SPFPlayer-infos {
  margin-bottom: 1rem;
}

.SPFPlayer-infosAuthor,
.SPFPlayer-infosDate {
  display: none;
}

.SPFPlayer-infosDuration {
  display: none;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;

  @include theme.themify {
    background: theme.$color-base;
  }
}

.SPFPlayer-infosTitle {
  display: none;
  margin-bottom: 0.6rem;
  font-size: 1.4rem;

  @media (mq.$from-tablet) {
    font-size: 3.2rem;
  }

  // margin: 0 1.4rem;
  // margin-bottom: 2rem;

  >span {
    @include theme.themify {
      background-color: theme.$color-light;
      box-shadow: 1.4rem 0 0 theme.$color-light, -1.4rem 0 0 theme.$color-light;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }
}

.SPFPlayer-mediaPlayer {
  outline: none;
}

.SPFPlayer-player {
  margin-bottom: 2rem;
  border-radius: 30px;
  overflow: hidden;
  transition: 0.2s;

  audio {
    width: 100%;
  }

  @include theme.themify {
    background: theme.$color-base;
    border: 5px solid theme.$color-base;
  }

  svg {
    border-radius: 50%;

    path {
      transition: 0.2s;
      color: #fff;
    }
  }
}

.SPFPlayer-playerButton {
  transition: 0.5s;
}

.SPFPlayer-playerButton--svg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  width: 75px;
  height: 75px;
  transform: translate(-50%, -50%);
  @include theme.themify {
    background: theme.$color-base;
  }

  svg {
    width: 50px;
    height: 50px;

    path{
      fill: #fff;
    }
  }
}

.SPFPlayer-transcription {
  display: inline-block;
  margin-bottom: 2rem;
  padding: 0;
  border-bottom: 2px solid colors.$black;
  box-shadow: none;
  border-radius: 0;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  color: currentColor;
  background-color: #fff;
  transition: 0.15s;
  transition-property: border, background-color, color;
  cursor: pointer;

  span {
    display: inline;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    border-color: colors.$green;

    @include theme.themify {
      border-color: theme.$color-base;
    }
  }

  @media (mq.$from-tablet) {
    font-size: 1.6rem;
  }
}