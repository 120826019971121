/*! Seasonfilter css component */

@use 'utilities/mq';
@use 'utilities/colors';

.Seasonfilter {
  // padding: 30px 0;
  background: #fff;
  padding: 20px 0;
  transition: padding .3s;
}

.Seasonfilter.isSticky {
  position: fixed;
  z-index: 10;
  left: 0;
  width: 100%;
  margin: 0 auto;
  border: 1px solid colors.$lighterGrey;
  background-color: #fff;

  .Seasonfilter-container--withLine {
    max-width: 95%;
  }

  @media (mq.$phone) {
    // padding-top:20px;
    // padding-bottom:10px;
  }

  .Seasonfilter-container {
    max-width: mq.$layout-width;
  }
}

.Seasonfilter-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;

  @media (mq.$from-tablet) {
    padding: 10px 20px;
  }
}

.Seasonfilter-container--small {
  width: mq.$layout-small;

  @media (mq.$phone) {
    width: 100%;
  }
}

.Seasonfilter-container--medium {
  width: mq.$layout-medium;
}

.Seasonfilter-container--withLine {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;

  @media(mq.$from-tablet) {
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 30px;
      height: 2px;
      background-color: colors.$lighterGrey;
    }
  }
}

.Seasonfilter-monthContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2.5rem;

  @media (mq.$from-tablet) {
    justify-content: space-between;
    padding-bottom: 2rem;
  }

  .Cta {
    margin: 0;

    &:first-child {
      transform: rotate(180deg);
    }
  }
}

.Seasonfilter-month {
  width: 100%;
  max-width: 300px;
  padding: 0 40px;
  font-weight: 700;
  font-size: 2.2rem;
  overflow: hidden;
  text-align: center;

  @media (mq.$from-tablet) {
    max-width: 100%;
    font-size: 4.2rem;
  }
}

.Seasonfilter-filters {
  display: inline-flex;
  justify-content: flex-start;
  padding-bottom: 0;
  background-color: #fff;
  overflow-x: auto;

  @media (mq.$from-tablet) {
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 2rem 0;
  }

  @media (mq.$phone) {
    padding-bottom: 15px;
    margin-bottom: -15px;
  }

  .Cta {
    margin: 0 5px 0;
    white-space: nowrap;

    &[data-active=true] {
      border-color: colors.$black;
      background-color: colors.$black;
      color: #fff;
    }
  }
}