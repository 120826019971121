/*! Tags css component */

@use 'utilities/mq';
@use 'utilities/colors';

.Tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 30px 0;
  padding-bottom: 30px;
  border-bottom: 1px solid colors.$lightGrey;
}

.Tags .Cta {
  &:not(:last-child) {
    margin-right: 1rem;
  }
}