/*! Share */

@use 'utilities/mq';

.Share--marginTop {
  margin-top: 2rem;

  @media(mq.$desktop) {
    margin-top: 4rem;
  }
}

.Share-mobile {
  display: flex;
  justify-content: center;
  max-height: 200px;
  transition: max-height .3s ease-in-out;
  overflow: hidden;

  &[aria-hidden=true] {
    max-height: 0;
  }
  
  .Cta {
    font-size: 1.4rem;
    
    .icon {
      width: 20px;
      height: 20px;
    }
  }

  @media (mq.$desktop) {
    display: none;
  }
}

.Share-group {
  // display: none;
  max-height: 0; 
  overflow: hidden;
  transition: max-height .7s ease-in-out;
  
  @media (mq.$desktop){
    max-height: 600px;
  }

  @media (mq.$upto-tablet){
    display: flex;
    margin-bottom: 20px;
    
    &[aria-hidden=false] {
      max-height: 600px;
    }
  }

  @media (mq.$phone){
    flex-direction: column;
    align-items: flex-start;
  }

  @media (mq.$tablet){
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media (mq.$desktop){
    display: block;
  }
  
  .Cta {
    font-size: 1.6rem;
    
    @media (mq.$phone){
      width:100%;
      font-size: 1.4rem;
    }

    @media (mq.$tablet){
      width: 49%;
    }
    
    span {
      justify-content: flex-start;
      
      .icon {
        @media (mq.$upto-tablet){
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}