/*! Crosslink component */
@use 'utilities/mq';

.Crosslink--center {
  .Crosslink-description,
  .Crosslink-title {
    text-align: center;
  }
}

.Crosslink-title,
.Crosslink-description {
  max-width: 730px;
  margin-bottom: 4rem;

  .Crosslink--center & {
    margin: 0 auto 4rem;
  }
}

.Crosslink-title {
  &.title2 {
    font-size: 2.3rem;

    @media (mq.$desktop) {
      font-size: 3rem;
    }
  }
}

.Crosslink-toggle {
  display: flex;
  margin-bottom: 4rem;
  justify-content: center;

  .Cta {
    margin: 0 1rem 1rem 1rem;
  }
}
.Crosslink-cards .GridCard {
  margin: 0 0 3rem;
}
