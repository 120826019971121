



































































.Controls-button[disabled] {
  cursor: initial;
}
