/*! QuizResults scss component */
@use 'utilities/colors';
@use 'utilities/mq';

.QuizResults {
  text-align: center;

  .Cta {
    border: 1px solid transparent;

    &:hover {
      border: 1px solid colors.$lighterGrey;
    }
  }

  .QuizResults-image {
    max-height: 180px;
  }

  &.title2 {
    font-size: 2.3rem;

    @media (mq.$desktop) {
      font-size: 3rem;
    }
  }
}

.QuizResults-row:not(:last-child) {
  margin-bottom: 2rem;

  @media (mq.$from-tablet) {
    margin-bottom: 4rem;
  }
}
.QuizResults-title {
  @media (mq.$from-tablet) {
    margin-bottom: 4rem;
  }
}
