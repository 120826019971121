/*! FoodSeason scss component */

@use 'utilities/colors';
@use 'utilities/mq';

.FoodSeason {
  margin-bottom: 3rem;

  @media (mq.$from-tablet) {
    margin-bottom: 5rem;
  }

  .Drawer--food & {

    @media (mq.$phone) {
      margin-top:-4rem;

      .FoodSeason-title {
        max-width: 80%;
      }
    }
  }
}

.FoodSeason-months {
  display: flex;
  overflow-x: auto;
}

.FoodSeason-month {
  margin-bottom: 2.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: colors.$lightGrey;
  
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
  
  @media (mq.$from-tablet) {
    font-size: 2rem;
  }
  
  @media (mq.$phone) {
    margin-bottom: 1.5rem;
  }
}

.FoodSeason-month--current {
  color: colors.$black;
}

.FoodSeason-caption {
  display: block;
  margin-bottom: 2.7rem;
}