/*! bookmark scss component */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';
@use 'utilities/pageTheme'as theme;

.Bookmark {
  .Cta {
    padding: 8px;

    .icon {
      width: 15px;
      height: 15px;
    }
  }
}

.Bookmark,
.Bookmark--bookmarked {

  display: none; // div is displayed by JS (see bookmark.js)

  .Cta--iconStandalone.Cta--outline {

    &:hover,
    #{variables.$keyboard} &:focus {

      @include theme.themify {
        color: theme.$color-base;
        border-color: theme.$color-base;
      }

      .icon {
        @include theme.themify {
          color: theme.$color-base;
        }
      }
    }
  }
}

@each $theme,
$colors in theme.$activityTheme-colors {
  [data-activity="#{$theme}"] {

    .Bookmark,
    .Bookmark--bookmarked {
      .Cta--iconStandalone.Cta--outline {

        &:focus,
        &:hover {
          color: map-get($colors, 'base');
          border-color: map-get($colors, 'base');

          .icon {
            color: map-get($colors, 'base');
          }
        }
      }
    }
  }
}