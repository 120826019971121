// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@use 'utilities/mq';
@use 'utilities/colors';

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    @media(mq.$desktop){
      &,
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    }
  } @else {
    @media(mq.$desktop){
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

// Switch to grayscale font smoothing on OSX
@mixin osx-font-smoothing-grayscale {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin add-scrollbar-style{
  &::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: #f0f0f0;
  }
  
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: colors.$lightGrey;
  }
}

