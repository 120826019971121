/*! Keypoints css component*/

@use 'utilities/colors';
@use 'utilities/mq';
@use 'utilities/variables';
@use 'utilities/pageTheme'as theme;

.Keypoints {
  text-align: center;
}

.Keypoints-bookmark {
  position: absolute;
  top: 30px;
  right: 30px;
}

.Keypoints-items {
  display: flex;
  justify-content: center;

  @media (mq.$upto-tablet) {
    flex-wrap: wrap;
  }
}

.Keypoints-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 0 10px;
  margin-bottom: 2rem;

  @media (mq.$desktop) {
    min-width: 250px;
    width: 25%;
  }
}

.Keypoints-title {
  &.title2 {
    font-size: 2.3rem;

    @media (mq.$desktop) {
      font-size: 3rem;
    }
  }
}

.Keypoints-imgContainer {
  max-width: 200px;

  @media (mq.$from-tablet) {
    height: 150px;
  }
}

.Keypoints-img {
  max-height: 100%;
}

.Keypoints-number {
  font-weight: 700;

  @include theme.themify {
    color: theme.$color-base;
  }

  font-size: 3.2rem;

  @media (mq.$desktop) {
    font-size: 3rem;
  }
}

.Keypoints-link {
  color: currentColor;
  text-decoration: underline;
  text-underline-position: under;

  font-size: 1.4rem;

  &::before {
    content: "";
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    @include theme.themify {
      text-decoration-color: theme.$color-base;
    }

    .Keypoints-text {
      @include theme.themify {
        color: theme.$color-base;
      }
    }
  }
}
