/*! CTA CSS component */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';
@use 'utilities/pageTheme'as theme;

.Cta {
  position: relative;
  display: inline-block;
  padding: 7px 20px;
  margin-bottom: 1rem;
  border-radius: 25px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;
  color: currentColor;
  background-color: transparent;
  transition: 0.15s;
  transition-property: border, background-color, color;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  @media (mq.$from-tablet) {
    font-size: 1.6rem;
  }

  @media (mq.$desktop) {
    font-size: 1.4rem;
    padding: 10px 20px;
  }

  &[aria-hidden="true"] {
    display: none;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
  }

  .icon {
    flex-shrink: 0;
    width: 11px;
    height: 11px;
    margin-right: 10px;
    color: colors.$black;
    user-select: none;
    transition: 0.15s;

    @media (mq.$from-tablet) {
      width: 16px;
      height: 16px;
    }
  }

  * {
    pointer-events: none;
  }
}

.Cta--light {
  background-color: #fff;
  border: 1px solid #ccc;

  &:hover,
  #{variables.$keyboard} &:focus {
    background-color: colors.$black;
    border-color: colors.$black;
    color: #fff;

    .icon {
      color: #fff;
    }
  }
}

.Cta--grey {
  background-color: #f0f0f0;

  &:hover,
  #{variables.$keyboard} &:focus {
    background-color: colors.$black;
    color: #f0f0f0;

    .icon {
      color: #fff;
    }
  }
}

.Cta--dark {
  background-color: colors.$black;
  border: 1px solid transparent;
  color: #fff;

  .icon {
    color: currentColor;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    background-color: #fff;
    border: 1px solid #ccc;
    color: colors.$black;
  }
}

.Cta--green {
  background-color: colors.$greenDark;
  color: #fff;

  .icon {
    color: #fff;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    background-color: colors.$black;
    color: #fff;
  }
}

.Cta--outline {
  border: 1px solid colors.$greenDark;
  box-shadow: unset;
  background-color: #fff;

  .icon {
    transition: color 0.15s;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    background-color: colors.$greenDark;
    color: #fff;

    .icon {
      color: colors.$greenDark;
    }
  }
}

.Cta--outlineBig {
  @extend .Cta--outline;
  padding: 18px 20px;
  border-radius: 35px;
  background-color: #fff;

  &:hover,
  #{variables.$keyboard} &:focus {
    color: #fff;

    .icon {
      color: #fff;
    }
  }

  .icon {
    margin-right: 0;
    margin-left: 20px;
  }
}

.Cta--underline {
  display: inline;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid colors.$black;
  transition-property: border, background-color, color, margin;

  &:hover,
  #{variables.$keyboard} &:focus {
    border-color: colors.$greenDark;
  }

  span {
    display: inline;
  }
}

.Cta--iconStandalone {
  padding: 10px;
  line-height: 0;
  border: none;

  .icon {
    margin: 0;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    border: none;
    background-color: colors.$green;
  }

  &.Cta--outline {
    background: transparent;
    border: 1px solid colors.$black;

    &:hover,
    #{variables.$keyboard} &:focus {
      border-color: colors.$green;
    }
  }

  &.Cta--green {
    background-color: colors.$green;
    color: #fff;

    .icon {
      color: #fff;
    }

    &:hover,
    #{variables.$keyboard} &:focus {
      background-color: colors.$black;
      color: #fff;
    }
  }

  &.Cta--light {
    background-color: #fff;
    color: colors.$black;

    .icon {
      color: currentColor;
    }

    &:hover,
    #{variables.$keyboard} &:focus {
      color: #fff;
      background-color: colors.$black;
    }
  }
}

.Cta--filter {
  border: 1px solid colors.$lighterGrey;

  &:hover,
  #{variables.$keyboard} &:focus {
    color: #fff;
    border-color: colors.$black;
    background-color: colors.$black;
  }
}

.Cta--filterBox {
  border: 1px solid colors.$lighterGrey;
  border-radius: 10px;

  &:hover,
  #{variables.$keyboard} &:focus {
    color: #fff;
    background-color: colors.$green;
  }
}

.Cta-bubbleInfo {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  top: -10px;
  right: -15px;
  font-weight: 700;
  border-radius: 50%;
  color: colors.$black;

  &[aria-hidden="true"] {
    display: none;
  }

  @include theme.themify {
    background-color: theme.$color-base;
  }
}

.Cta--inactive {
  background-color: colors.$lighterGrey;
  pointer-events: none;

  &:hover {
    background-color: colors.$lighterGrey;
    color: #fff;
  }
}