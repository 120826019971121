@use 'utilities/colors';
@use 'utilities/variables';

.FlashMessage {
  position: fixed;
  z-index: 500;
  left: 50%;
  bottom: -100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  max-width: 100%;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 1.4rem;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #fff;
  box-shadow: 0 -2px 15px rgba(0, 0, 0, .2);
  transition: bottom .3s;
  transform: translateX(-50%);

  &[aria-hidden=false] {
    bottom: 0;
  }

  .Cta {
    margin-bottom: 0;
    background-color: transparent;
    box-shadow: none;
    color: #fff;

    &:hover,
    #{variables.$keyboard} &:focus {
      background-color: #fff;
      color: colors.$black;
    }

    .icon {
      width: 12px;
      ;
      height: 12px;
    }
  }
}

.FlashMessage-text {
  display: flex;
  align-items: center;
  margin-right: 20px;

  &[data-flash-message-text=valid] {
    .icon:first-child {
      display: block;
    }

    .icon:last-child {
      display: none;
    }
  }

  &[data-flash-message-text=invalid] {
    .icon:first-child {
      display: none;
    }

    .icon:last-child {
      display: block;
    }
  }

  .icon {
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }
}

.FlashMessage-alert {
  background-color: colors.$redDark;
  color: white;
}

.FlashMessage-notice {
  background-color: colors.$greenDark;
  color: white;
}