/*! QuizStart scss component */

@use 'utilities/mq';

.QuizStart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media (mq.$phone) {
    padding-bottom: 40px;
  }

  @media (max-height: 700px) {
    padding-bottom: 100px;
  }
}

.QuizStart-title {
  text-align: center;

  @media (mq.$phone) {
    margin-left: 0;
    margin-right: 0;
  }

  &.title2 {
    font-size: 2.3rem;

    @media (mq.$desktop) {
      font-size: 2.4rem;
    }
  }
}

.QuizStart-image {
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 4rem;

  @media (mq.$phone) {
    max-width: 100%;
    width: auto;
    max-height: 130px;
  }
}

.QuizStart-connectionText {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 1.6rem;
  text-align: center;
}
