/* HeaderSimple */

@use 'utilities/mq';

.HeaderSimple {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  border-radius: 0 0 15px 15px;
  background-color: #fff;

  @media(mq.$from-tablet) {
    padding: 40px 0;
    border-radius: 0 0 60px 60px;
  }

  .icon {
    width: 212px;
    height: 18px;

    @media(mq.$from-tablet) {
      width: 224px;
      height: 36px;
    }
  }
}

.HeaderSimple-logo {
  margin: 0 auto;
}