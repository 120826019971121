/*! Teaser Component */

.Teaser {
  position: relative;
  display: block;
  max-width: 600px;
  border: solid black;
  background-color: white;
}

.Teaser-image {
  border-bottom: solid black;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

// Fixed height image with cover effect
// Needs a polyfill for IE11: https://caniuse.com/#search=object-fit
.Teaser-image--cover {
  img {
    height: 150px;
    object-fit: cover;
  }
}

.Teaser-content {
  padding: 15px;
}

.Teaser-title {
  font-size: 2.4rem;
  line-height: 1.2;
  color: black;

  a {
    color: inherit;
  }

  // Transparent overlay to capture clicks on the full Teaser
  a::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.Teaser-text {
  margin-top: 10px;
  font-size: 1.6rem;
  line-height: 1.4;
}