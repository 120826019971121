/*! FooterExternalLinks scss component */

@use 'utilities/mq';
@use 'utilities/colors';

.FooterExternalLinks-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (mq.$from-tablet) {
    flex-direction: row;
    padding: 40px 0;
  }
}

.FooterExternalLinks-topLogos {
  display: flex;
  align-items: center;

  @media (mq.$phone) {
    align-items: unset;
    flex-wrap: wrap;
    width: 100%;
  }
}

.FooterExternalLinks-topLogo {
  position: relative;
  display: block;
  height: 80px;
  overflow: hidden;
  opacity: 1;
  box-sizing: content-box;
  transition: 0.5s;

  @media (mq.$phone) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: unset;
  }

  .icon {
    height: 80px;
    flex-shrink: 0;

    @media (mq.$phone) {
      margin: 40px;
    }
  }

  &:nth-child(1) {
    width: 80px;

    @media (mq.$phone) {
      width: 50%;
      border-bottom: 1px solid colors.$lightGrey;
    }

    .icon {
      width: 80px;
    }
  }

  &:nth-child(2) {
    width: 110px;

    @media (mq.$phone) {
      width: 50%;
      border-bottom: 1px solid colors.$lightGrey;
    }

    .icon {
      width: 110px;
    }
  }

  // supposed to be the last child
  &:nth-child(3) {
    width: 150px;

    @media (mq.$phone) {
      width: 100%;
    }

    .icon {
      @media (mq.$phone) {
        margin: 0;
      }

      width: 150px;
    }
  }

  &:not(:last-child) {
    @media (mq.$from-tablet) {
      margin-right: 80px;
    }
  }

}

.FooterExternalLinks-topSocials {
  display: flex;
  align-items: center;

  @media (mq.$phone) {
    margin-bottom: 15px;
  }
}

.FooterExternalLinks-topSocial {
  &:not(:last-child) {
    margin-right: 15px;
  }
}