/*! UserInformations scss components */

@use 'utilities/mq';
@use 'utilities/colors';

.UserInformations {
  @media (mq.$phone) {
    padding: 40px 20px;
  }
}

.UserInformations-container {
  display: flex;
  flex-direction: column;

  @media (mq.$phone) {
    text-align: center;
  }

  &:not(:last-child) {
    margin-bottom: 50px;
  }
}

.UserInformations-labelContainer {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(colors.$black, .4);
  text-transform: uppercase;
  font-weight: 700;

  @media (mq.$phone) {
    justify-content: center;
    text-align: center;
  }

  .icon {
    margin-right: 15px;
  }
}

.UserInformations-button {
  margin-top: 20px;
  align-self: center;

  @media (mq.$from-tablet) {
    align-self: flex-end;
  }
}