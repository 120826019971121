/*! User RGPD scss component */

@use "utilities/mq";
@use "utilities/colors";

.UserRgpd {
  padding-bottom: 0;

  @media (mq.$from-tablet) {
    padding-bottom: 60px;
  }
}

.UserRgpd-title {
  margin-bottom: 4rem;
  text-transform: uppercase;
  text-align: center;
}

.UserRgpd-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;

  @media (mq.$phone) {
    flex-direction: column;
  }

  .Cta {
    &:not(:last-child) {
      @media (mq.$phone) {
        margin-bottom: 25px;
      }
    }
  }
}

.UserRgpd-dot {
  position: relative;
  top: -5px;
  width: 6px;
  height: 6px;
  margin: 0 20px;
  border-radius: 50%;
  background: colors.$greenDark;

  @media (mq.$phone) {
    display: none;
  }
}

.UserRgpd-timeline {
  text-align: center;
}

.UserRgpd-date {
  margin-bottom: 5px;
}