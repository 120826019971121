/* Form */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';

.Form {
  text-align: center;

  label {
    display: block;
    position: relative;
    text-align: left;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    width: 100%;
    padding: 13px 20px;
    font-weight: 400;
    border-radius: 5px;
    background-color: #fff;
    border: 2px solid colors.$black;
    transition: border .25s ease;

    &:invalid:not(:focus):not(:placeholder-shown) {
      border-color: colors.$redDark;
    }

    &:valid:not(:focus):not(:placeholder-shown) {
      border-color: colors.$greenDark;
    }
  }

  input[type="text"]+[data-passwordreveal-btn] [data-passwordreveal-hiddened] {
    display: none;
  }

  input[type="password"]+[data-passwordreveal-btn] [data-passwordreveal-revealed] {
    display: none;
  }
}

.Form-withIcon {
  padding-right: 65px;
}

.Form-twoCol {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.Form-twoCol--mobile {

  @media screen and (max-width: 450px) {
    flex-direction: column-reverse;
  }

  .Checkbox-check+span {
    line-height: 1.7; // as it's a block I just had a line-height so it is centered with the checkbox 
  }
}

.Form-label {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: 700;
  color: colors.$black;
}

.Form-reveal {
  position: absolute;
  right: 2px;
  bottom: 2px;
  height: 4.2rem;
  padding: 7px 20px;
  border-radius: 0 5px 5px 0;
  border-left: none;
  transition: background-color .25s ease;

  @media(mq.$from-tablet) {
    height: 4.6rem;
    padding: 8px 20px;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    background-color: colors.$lighterGrey;
  }

  .icon {
    width: 25px;
    height: 25px;
    vertical-align: middle;

    @media(mq.$from-tablet) {
      width: 30px;
      height: 30px
    }
  }
}

.Form-help {
  margin: 4rem 0;
  text-align: left;

  ul {
    max-width: 350px;
  }

  li {
    position: relative;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.6;
    transition: color .25s ease;

    &[data-passwordcheck-match="true"] {
      color: colors.$greenDark;
    }

    &[data-passwordcheck-match="false"] {
      color: colors.$redDark;
    }
  }
}

.Form-icon {
  position: absolute;
  display: none;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }
}

.Form-icon--valid {
  [data-passwordcheck-match="true"] & {
    display: block;
  }
}

.Form-icon--invalid {
  [data-passwordcheck-match="false"] & {
    display: block;
  }
}

.Form-error {
  margin-top: .5rem;
  color: colors.$redDark;
  text-align: left;

  &[aria-hidden='true'] {
    display: none;
  }
}

.Form-helpTitle {
  margin-bottom: 3rem;
}

.Form-link {
  margin-left: 5px;
  font-weight: 500;
  text-decoration: underline;
  text-align: right;
}

.Form-submit {
  display: inline-block;
  margin-top: 2rem;
}

/*! Checkbox */
.Checkbox {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  letter-spacing: 0;
  cursor: pointer;
  user-select: none;

  @media(mq.$from-tablet) {
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  a {
    text-decoration: underline;
  }
}

/* Hide the browser's default checkbox */
.Checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.Checkbox-check {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: 2px solid colors.$black;
  border-radius: 4px;
  background-color: #fff;
}

/* On hover and focus, add a grey background color */
.Checkbox:hover input~.Checkbox-check,
.Checkbox input:focus~.Checkbox-check {
  background-color: colors.$lightGrey;
}


/* Create the Checkbox-check/indicator (hidden when not checked) */
.Checkbox-check:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the Checkbox-check when checked */
.Checkbox input:checked~.Checkbox-check:after {
  display: block;
}

/* Style the Checkbox-check/indicator */
.Checkbox .Checkbox-check:after {
  left: 9px;
  top: 5px;
  width: 8px;
  height: 12px;
  border: solid colors.$black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}