/*! QuizNumber scss component */

@use 'utilities/mq';
@use 'utilities/variables';
@use 'utilities/colors';

.QuizInput-row {
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    position: relative;
    display: block;
    padding: 0;
    margin-bottom: 10px;
    border: none;
    font-weight: 700;
    text-align: center;
    color: #201f2c;
    transition: 0.15s;

    @media (mq.$from-tablet) {
      text-align: left;
    }

    &::after,
    &::before {
      display: none;
    }
  }

  >div {
    &:first-child {
      margin-bottom: 20px;

      @media (mq.$from-tablet) {
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }

  @media (mq.$from-tablet) {
    flex-direction: row;
  }
}

.QuizInput-Number {
  width: 100%;
  padding: 10px;
  font-size: 1.6rem;
  border-radius: 10px;
  border: 1px solid colors.$black;
  outline: none;
  background-color: #fff;

  #{variables.$keyboard} &:focus-visible {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px colors.$black;
  }

  @media (mq.$from-tablet) {
    max-width: 300px;
  }
}