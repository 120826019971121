/*! Intro module css component  */

@use 'utilities/mq';

.IntroActivite {
  @media(mq.$phone) {
    margin-top: -40px
  }

  .IntroActivite-bookmark {

  }
}

.IntroActivite-info {
  font-size: 1.6rem;
  line-height: 1.45;
  display: flex;
  align-items: center;

  @media(mq.$from-tablet) {
    font-size: 2.4rem;
  }

  .icon {
    margin-right: 10px;
    width: 2.2rem;
    height: 2.2rem;
    color: #929292;
  }

  span {
    font-weight: 700;
  }
}

.IntroActivite-info--type{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.IntroActivite-bookmark {
  position: absolute;
  top: 20px;
  right: 20px;
}

.IntroActivite-img {
  margin-top: 3.5rem;
  margin-bottom: 2rem;
  border-radius: 20px;

  @media(mq.$from-tablet) {
    margin-top: 4rem;
  }
}

.IntroActivite-share {
  display: none;
  margin-bottom: 30px;
  
  @media(mq.$upto-tablet) {
    display: block;
  }
  
  @media(mq.$phone) {
    margin-bottom: 20px;

    .Share--marginTop {
      margin-top: 10px;
    }
  }

}

.container--activityFull {
  .container-inner {
    > div {
      > .Share {
        @media(mq.$upto-tablet) {
          display: none;
        }
      }
    }
  }
}