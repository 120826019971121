/* PromotedSliderIntro css component */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';
@use 'utilities/pageTheme'as theme;

.PromotedSliderIntro {
  display: flex;
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;
  margin-top: 2rem;
  padding-bottom: 60px;

  @media (mq.$desktop) {
    margin-top: 0;
  }

  @media (mq.$phone) {
    margin-top: -20px;
  }
}

.PromotedSliderIntro--home {
  @media (mq.$phone) {
    padding-bottom: 0;
  }
}

.PromotedSliderIntro-title {
  @media (mq.$phone) {
    font-size: 2.2rem;
  }

  .PromotedSliderIntro--home & {
    @media (mq.$phone) {
      display: none;
    }
  }
}

.PromotedSliderIntro-slider {
  width: 66%;
  max-width: 625px;
  margin-bottom: 2rem;

  @media (mq.$upto-tablet) {
    width: 100%;
  }

  @media (mq.$desktop) {
    margin-bottom: 0;
  }
}

.PromotedSliderIntro-intro {
  width: 31.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (mq.$upto-tablet) {
    width: 100%;
  }
}

.PromotedSliderIntro-title.title4 {
  font-size: 3rem;
  margin-bottom: 3rem;
  line-height: 3.7rem;
}

.container[data-container-twocols-custom] {
  .container-inner {
    > div:nth-child(2) {
      @media (mq.$desktop) {
        width: 39%;
      }
    }
  }
}

.PromotedSliderIntro-text {
  font-size: 1.6rem;
  overflow: hidden;
  transition: max-height 0.25s ease;
}

.PromotedSliderIntro-seeMore {
  display: block;
  margin-top: 1rem;
  font-weight: 500;
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: colors.$black;
  text-decoration-thickness: 3px;
  transition: 0.15s;
  font-size: 1.6rem;
  align-self: flex-start;

  @media (mq.$desktop) {
    font-size: 1.4rem;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    text-decoration-color: colors.$green;
  }
}

.PromotedSliderIntro-tools {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (mq.$phone) {
    display: none;
  }

  .Cta {
    width: 100%;
    margin-bottom: 1.8rem;
    font-size: 1.4rem;

    span {
      justify-content: space-between;
      width: 100%;
    }
    &.Cta--outlineBig {
      padding-block: 1.05rem;
      border-width: 1px;
    }
  }
}

.PromotedSliderIntro-tools--noPaddingTop {
  margin-top: 0;
}

.PromotedSliderIntro--video {
  gap: 0;

  @media (mq.$desktop) {
    gap: 4rem;
  }

  .PromotedSliderIntro-intro {
    justify-content: flex-start;
  }

  .SPFPlayer-player {
    height: 246px;
    border: none;

    @media (mq.$from-tablet) {
      height: 370px;
    }
  }

  .SPFPlayer-mediaPlayer {
    height: 246px;
    object-fit: cover;

    @media (mq.$from-tablet) {
      height: 370px;
    }
  }

  .SPFPlayer-playerButton {
    display: none;
  }
}
