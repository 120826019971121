/*! Settings scss component */
@use 'utilities/colors';
@use 'utilities/mq';

.Settings {
  text-align:center;
}

.Settings-title {
  margin-bottom: 2rem;
  
  @media (mq.$from-tablet){
    margin-bottom: 4rem;
  }

  &.title2{
    font-size: 2.3rem;
  
    @media (mq.$desktop){
      font-size: 3.6rem;
    }
  }
}

.Settings-text {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

.Settings-button {
  display: inline-block;
}
