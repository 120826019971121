/*! Modal scss component*/

@use 'utilities/mq';

$transitionTime: 0.5s; // if you modify this think to modify the JS => const transitionTime

.Modal {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;

  .SPFPlayer-transcription,
  .SPFPlayer {
    margin-bottom: 0;
  }

  .SPFPlayer {
    width: unset;

    @media (mq.$from-tablet) {
      width: 550px;
    }
  }
}

.Modal[aria-hidden="false"] {
  .Modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .Modal-container {
    top: 50%;
    opacity: 1;
  }
}

.Modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: background-color $transitionTime;
}

.Modal-container {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;
  width: 100%;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
  padding: 40px;
  transform: translate(-50%, -50%);
  transition: $transitionTime;
  overflow-y: auto;
  border-radius: 30px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  opacity: 0;
  background: #fff;

  @media (mq.$from-tablet) {
    padding: 40px 60px;
    width: unset;
  }

  .Cta {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.Modal--alt[aria-hidden="false"] {
  .Modal-overlay {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

.Modal--alt {  
  .Modal-container {
    width: 100%;
    max-width: 1180px;
    background: transparent;
    box-shadow: none;
  }

  .SPFPlayer {
    width: unset;
  }

  .SPFPlayer-player {
    border: none;
    background: transparent;
  }

  .SPFPlayer-playerButton {
    display: none;
  }
}
