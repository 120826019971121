/*! FooterSimple */

@use 'utilities/colors';
@use 'utilities/mq';
@use 'utilities/variables';

.FooterSimple {
  margin-top: 50px;

  @media(mq.$from-tablet) {
    margin-top: 100px;
  }

  .container--radiusTop {
    padding: 5px 0;

    @media(mq.$from-tablet) {
      padding: 10px 0;
    }
  }

}

.FooterSimple-logos {
  position: relative;
  display: flex;
  align-items: center;
}

.FooterSimple-logo {
  @media(mq.$from-tablet) {
    margin-right: 70px;
  }
}

.FooterSimple-logo--mb {
  @media(mq.$phone) {
    display: none;
  }

  .icon {
    width: 160px;
    height: 40px;
  }
}

.FooterSimple-logo--spf {
  @media(mq.$phone) {
    width: 50%;
    text-align: center;
  }

  .icon {
    width: 115px;
    height: 70px;
  }
}

.FooterSimple-logo--rf {
  @media(mq.$phone) {
    width: 50%;
    text-align: center;
  }

  .icon {
    width: 100px;
    height: 90px;
  }
}

.FooterSimple-links {
  .container {
    padding: 35px 0;
  }

  .container-inner {
    padding: 0 15px;

    @media(mq.$phone) {
      max-width: 230px;
      margin: 0 auto;
    }
  }
}

.FooterSimple-link {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  transition: opacity .25s ease;

  @media(mq.$phone) {
    margin: 0 0 15px 0;
    font-weight: 700;
  }

  @media(mq.$from-tablet) {
    display: inline-block;
    opacity: .8;

    &:hover,
    #{variables.$keyboard} &:focus {
      opacity: 1;
    }

    &:not(:last-child) {
      position: relative;
      margin-right: 33px;

      &:after {
        content: "";
        position: absolute;
        right: -15px;
        top: 50%;
        width: 3px;
        height: 3px;
        border-radius: 100%;
        background-color: #fff;
        transform: translateY(-50%);
      }
    }
  }
}

// @use 'utilities/colors';

.alert-success,
.alert-warning,
.alert-error,
.alert-info {
  margin-top: 30px;
  padding: 20px;
  text-align: center;
  background-color: transparent;
  border-radius: 10px;
  font-size: 1.8rem;
  font-weight: 600;
  text-shadow: 0 1px rgba(0,0,0,.05);
  color: #fff;
  max-height: 0;
  transition: max-height .2s;
  overflow: hidden;

  .alert-icon{
    line-height: 1;

    .icon {
      background-color: rgba(255,255,255,.2);
      border-radius: 50%;
      padding: 8px;
      width: 50px;
      height: 50px;
      margin-bottom: 5px;
    }
  }
  
  &[aria-hidden=true] {
    max-height: 0;
  }
  
  &[aria-hidden=false] {
    max-height: 500px;
    transition: max-height .2s .2s;
  }
}

.alert-success {
  background-color: colors.$green;
}
.alert-warning {
  background-color: colors.$orange;
}
.alert-error {
  background-color: colors.$red;
}
.alert-info {
  background-color: colors.$grey;
}