/*! Glossary scss component*/

@use 'utilities/mq';

.Glossary {}

.Glossary-tab {
  position: relative;
  min-height: 180px;
  padding: 0;
  box-shadow: -1px -5px 40px 0px rgba(0, 0, 0, 0.10);
  overflow: hidden;
  background: #fff;
}

.Glossary-container {
  padding: 20px 20px 60px;
  transition: 0.8s ease-in-out;

  &[aria-hidden=false] {
    .Glossary-btn:not(:disabled):not([disabled=disabled]) {
      transform: rotate(-90deg);
    }
  }

  &[aria-hidden=true] {
    .Glossary-btn {
      transform: rotate(90deg);
    }

    .Glossary-definition {
      opacity: 0;
    }
  }
}

.Glossary-letter {
  padding: 0 0 4rem;
  text-align: center;
  font-size: 6rem;
  font-weight: 700;
}

.Glossary-definition {
  padding-bottom: 3rem;
  transition: 0.5s;

  @media (mq.$from-tablet) {
    padding-bottom: 6rem;
  }
}

.Glossary-ressources {
  text-decoration-thickness: 2px;
  text-decoration: underline;
  text-underline-position: under;

  .Ressources {
    padding: 2rem 0 0 0;
    margin-bottom: 0;
    border: none;
  }

  .Ressources-title {
    display: none;
  }
}

.Glossary-btn {
  position: absolute;
  top: 4.4rem;
  right: 4rem;
  transform: rotate(-90deg);
  transform-origin: center center;
  transition: 0.2s;

  .Cta {
    margin-bottom: 0;

    * {
      pointer-events: none
    }
  }
}

.Glossary-empty {
  padding-bottom: 2rem;
  text-align: center;
  color: #777;
  font-style: italic;

  span {
    font-weight: 700;
  }
}