/*! QuizStep.scss component */

@use 'utilities/mq';

.QuizStep {
  text-align: center;

  padding-bottom: 30px;

  @media (mq.$desktop) {
    padding-bottom: 75px;
  }
}
