/*! Header css component */
@use 'utilities/mq';


.Header {
  position: fixed;
  display: flex;
  z-index: 100;
  top: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 35px 35px;
  transition: border-radius .15s;

  &[data-navigation=open] {
    border-radius: 0 0 0 0;
  }

  @media (mq.$desktop-navigation) {
    display: block;
    border-radius: 0 0 60px 60px;
    overflow: visible;
  }
}

.Header.isSticky {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Header-skip-button{
  position: absolute;
}