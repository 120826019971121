/*! QuizQuestion scss component */

@use 'utilities/mq';
@use 'utilities/colors';

.QuizQuestion-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (mq.$from-tablet) {
    flex-direction: row;
    align-items: unset;
  }
}

.QuizQuestion-title {
  @media (mq.$phone) {
    font-size: 2rem;
    margin-left: 0;
    margin-right: 0;
  }

  &.title3 {
    font-size: 2.1rem;
    margin-bottom: 1.5rem;

    @media (mq.$from-tablet) {
      font-size: 2.4rem;
      margin-bottom: 3rem;
    }
  }
}

.QuizQuestion-left,
.QuizQuestion-right {
  width: 100%;
}

.QuizQuestion-left {
  text-align: center;

  @media (mq.$from-tablet) {
    width: 40%;
    min-width: 300px;
  }
}

.QuizQuestion-image {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;

  img {
    max-height: 130px;
    margin: 0 auto;

    @media (mq.$from-tablet) {
      max-height: 200px;
    }
  }

  @media (mq.$from-tablet) {
    margin-bottom: 0;
  }
}

.QuizQuestion-image--standalone {
  @media (mq.$from-tablet) {
    margin-top: 100px;
  }
}

.QuizQuestion-right {
  text-align: center;

  @media (mq.$from-tablet) {
    width: 60%;
    padding-left: 30px;
    text-align: left;
  }
}

.QuizQuestion-text {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: 700;

  @media (mq.$from-tablet) {
    margin-bottom: 20px;
  }
}

.QuizQuestion-footer {
  margin-top: 40px;
  text-align: center;

  @media (mq.$from-tablet) {
    text-align: left;
  }
}
