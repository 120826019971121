/* Category */

@use 'utilities/mq';
@use 'utilities/colors';

.Category {
  padding: 60px 0;

  &:not(:last-child) {
    border-bottom: 2px solid colors.$lighterGrey;
  }

  @media (mq.$from-tablet) {
    padding: 60px 0;
  }
}

.Category-content {
  overflow: hidden;

  @media (mq.$from-tablet) {
    display: flex;
  }
}

.Category-head {
  position: relative;
  max-width: 820px;
  margin: 0 auto 35px;
  text-align: center;

  @media (mq.$from-tablet) {
    flex: 1 1 100%;
  }
}

.Category-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Category-title {
  display: inline-block;
  width: 80%;
  margin: 0 0.7rem;
  font-size: 2.3rem;
  line-height: 3rem;

  span {
    background: #fff;
    box-shadow: 1.4rem 0 0 #fff, -1.4rem 0 0 #fff;
  }

  @media (mq.$desktop) {
    font-size: 3.6rem;
    line-height: 4.7rem;
  }
}

.Category-links {
  flex: 1 1 50%;
}

.Category-items {
  display: flex;
  flex-wrap: wrap;
  max-width: 620px;
  margin: 0 auto;
  margin-bottom: -2px; // prevent
  transition: height 0.25s ease;
  overflow: hidden;

  li {
    flex: 0 1 50%;
    margin-bottom: 1rem;
    text-align: center;
  }

  .Cta {
    line-height: 2;
    display: inline;
    border: none;
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 2px;
    text-underline-offset: 1px;

    &[aria-expanded="true"] {
      margin-bottom: -2px; // prevent
      color: colors.$greenDark;
    }
  }
}

.Category-more[aria-hidden="true"] {
  height: 0;
}

.Category-btn {
  margin: 30px 0 0;
  text-align: center;

  @media (mq.$from-tablet) {
    margin: 30px 0 0;
  }
}

.Category-blockquote {
  flex: 1 1 50%;
  padding: 0 20px;
}

.Category-text-to-show {
  display: grid;
  place-items: center;
  text-align: center;

  max-width: 620px;
  margin: 0 auto 35px;

  @media (mq.$from-tablet) {
    flex: 1 1 100%;
  }

  .Category-text-checkbox:checked ~ .Category-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .Category-text-button::after {
    content: "Voir moins";
  }

  .Category-text-checkbox:checked ~ .Category-text-button::after {
    content: "Voir plus";
  }

  .Category-text-button {
    display: inline-block;
    margin-block: 1rem 2rem;
    text-transform: lowercase;
    order: 1;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .Category-text-checkbox {
    display: none;
  }
}

.Category-crosslinks {
  display: grid;
  place-items: center;
  text-align: center;

  max-width: 620px;
  margin: 0 auto 35px;
  margin-block-start: 3rem;

  @media (mq.$from-tablet) {
    flex: 1 1 100%;
  }

  @media (mq.$desktop) {
    margin-block-start: 4rem;
  }
}

.Category-image {
  width: 100%;
}