/*! SearchBar scss component */

@use 'utilities/pageTheme'as theme;
@use 'utilities/colors';
@use 'utilities/mq';
@use 'utilities/variables';


.SearchBar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: padding .15s;
  
  @media (mq.$phone) {
    &[data-search-cover=open] {
      padding-bottom: 60px;
    }
  }
}

.SearchBar-header{
  display: none;
  position: relative;
  
  @media (max-width: mq.$layout-navigation) {
    display: block;
  }
}

.SearchBar-title{
  margin-bottom:10px;
  padding: 5px 0 12px 0;
  font-size: 2.4rem;
  font-weight:700;
  text-align:center;
}

.SearchBar-buttonNAvigationMobile{
  position: absolute;
  top:0;
  right:0;
}

.SearchBar-form {
  position: absolute;
  display: none;
  top: 30px;
  width: 100%;
  opacity: 0;
  transition: .3s;
}

.SearchBar-button {
  position: relative;

  .Cta {
    top: 0;
    transition: .3s;

    @media (mq.$phone) {
      padding: 8px 12px;
    }

    &[aria-hidden=true] {
      display: inline-block;
      visibility: hidden;
    }
  }
}

.SearchBar-formRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  @media (mq.$from-tablet) {
    flex-direction: row;
  }
}

.SearchBar-inputContainer {
  position: relative;
  width: 100%;
  max-width: 450px;

  .icon {
    position: absolute;
    left: 15px;
    top: 50%;
    width: 25px;
    height: 25px;
    transform: translateY(-50%);
    
    @media (mq.$phone) {
      top: calc(50% - 10px);
    }
  
  }
}

.SearchBar-input {
  display: block;
  width: 100%;
  padding: 12px 10px 12px 50px;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, .1);
  box-shadow: inset 3px 3px 10px rgba(0, 0, 0, .1);
  font-size: 1.8rem;
  outline: none;
  transition: .15s;

  @media (mq.$phone) {
    margin-bottom: 20px;
  }

  #{variables.$keyboard} &:focus {
    border-color: rgba(0, 0, 0, .2)
  }

  &::placeholder {
    font-style: italic;
    color: colors.$lightGrey;
  }
}

.SearchBar-submission {
  margin-left: 20px;
  flex-shrink: 0;

  .Cta {
    margin-bottom: 0;
    text-transform: uppercase;
    border: 2px solid transparent;
    
    &:hover, #{variables.$keyboard} &:focus {
      border: 2px solid colors.$lighterGrey;
    }
  }
}