/*! FoodDescription scss component */

.FoodDescription-image {
  margin-bottom: 2rem;
  border-radius: 20px;
  overflow: hidden;
  
  img {
    border-radius: 20px;
    width: 100%;
  }
}
