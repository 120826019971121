/*! CardConseil */

@use 'utilities/colors';
@use 'utilities/mq';

.CardConseil {
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 492px;
  width: 100%;
  // padding: 40px;
  // margin: 0 0 4rem 4rem;
  margin: 0 0 4rem 2.9rem;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  height: 246px;

  @media screen and (min-width: 745px) {
    max-width: max(calc((100% / 2) - 2.9rem), 335px);
    height: 246px;
  }

  @media (mq.$desktop) {
    // height: 492px;
    height: 430px;
    border-radius: 20px;
    // max-width: calc((100%/3) - 4rem);
    max-width: min(calc((100% / 3) - 2.9rem), 298px);
    border-radius: 20px;
  }
}

.CardConseil-content {
  position: relative;
  width: 80%;
  height: max-content;
  max-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0px 8px 8px 0 rgba(0, 0, 0, 0.32);

  @media (mq.$desktop) {
    width: 70%;
    height: max-content;
    min-height: 60%;
    max-height: 80%;
  }
}

.CardConseil-tag {
  position: absolute;
  top: -15px;
  left: 50%;
  padding: 5px 10px;
  font-weight: 700;
  color: #fff;
  background-color: colors.$black;
  transform: translateX(-50%);

  font-size: 1.6rem;

  @media (mq.$desktop) {
    font-size: 1.2rem;
  }
}

.CardConseil-title {
  text-align: center;
  font-size: 1.65rem;
  font-weight: 600;
  line-height: 1.2;
  margin-block-start: 1rem;

  @media (mq.$from-tablet) {
    margin: 0;
    font-weight: 600;
    font-size: 2.2rem;
  }
}

.CardConseil--promoted {
  max-width: calc(100% - 2rem);
  min-height: 250px;
  box-sizing: border-box;
  margin: 0;
}
