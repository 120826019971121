/*! Quiz Header scss component */

@use 'utilities/colors';
@use 'utilities/mq';

.QuizHeader {
  .titleBackground {
    // max-width: calc(100% - 90px);
    max-width: 100%;
    margin: 0 auto 20px;
  }

  & .title2 {
    font-size: 2.3rem;

    @media (mq.$desktop) {
      font-size: 3rem;
    }
  }
}

.QuizHeader-image {
  width: 100%;
  max-width: 180px;
  max-height: 150px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  @media (mq.$from-tablet) {
    max-height: 180px;
    margin-bottom: 30px;
  }

  img {
    max-height: 150px;
    height: 150px;
    object-fit: contain;

    @media (mq.$from-tablet) {
      max-height: 180px;
      height: 180px;
    }
  }
}

.QuizHeader-text {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 1.8rem;
  b {
    text-decoration: underline;
  }
}

.QuizHeader-exemple {
  margin-bottom: 30px;
  font-weight: 100;
  font-size: 1.8rem;
  color: rgba(colors.$black, 0.8);
}

.QuizHeader-infos {
  margin-bottom: 30px;
  font-weight: 400;
}

.QuizHeader-infoscontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.QuizHeader-infosText {
  font-size: 1.8rem;
}

.QuizHeader-infosBubble {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  padding: 8px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid colors.$black;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1;
}
