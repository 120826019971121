/*! PageNotFound scss component*/

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';

.PageNotFound {
  .SearchBar {
    width: 100%;
  }
}

.PageNotFound-header {
  padding: 40px 0 60px;
  margin-bottom: 5rem;
  border-bottom: 2px solid colors.$lightGrey;
}

.PageNotFound-title {
  font-size: 15rem;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;

  @media(mq.$from-tablet) {
    font-size: 22.6rem;
  }

}

.PageNotFound-text {
  text-align: center;
  font-size: 2.5rem;

  @media(mq.$from-tablet) {
    font-size: 3.2rem;
  }

  span {
    display: block;
    font-weight: 700;

    &:first-child {
      font-size: 3rem;

      @media(mq.$from-tablet) {
        font-size: 3.6rem;
      }
    }
  }
}

.PageNotFound-sitemap {
  display: block;
  margin-top: 1rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.8rem;
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-thickness: 3px;
  text-underline-offset: 3px;
  transition: .15s;

  &:hover,
  #{variables.$keyboard} &:focus {
    text-decoration-color: colors.$green;
  }
}

.PageNotFound-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  @media(mq.$from-tablet) {
    flex-direction: row;
  }

  .Cta {
    margin-bottom: 0;
  }
}

.PageNotFound-searchBar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-right: 0;
  margin-bottom: 2rem;
  padding: 10px 12px;
  border-radius: 60px;
  border: 1px solid colors.$lightGrey;
  background: #fff;
  box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.12);

  span {
    margin-left: 2rem;
    font-size: 1.4rem;
    color: #909090;

    @media(mq.$from-tablet) {
      font-size: 1.8rem;
    }
  }

  @media(mq.$from-tablet) {
    margin-right: 2rem;
    margin-bottom: 0;
  }
}

.PageNotFound-icon {
  height: 20px;
  margin-left: 1rem;
}