/*! CardSlider scss component */

@use 'utilities/colors';
@use 'utilities/mq';
@use 'utilities/variables';

.CardSlider {
  position: relative;

  .Card {
    margin: 0;

    @media (mq.$phone) {
      justify-content: space-between;
    }

    .Card-bottom {
      padding-bottom: 4.5rem;
      justify-content: center;
      width: 100%;
      max-width: 100%;

      @media (mq.$desktop) {
        padding-bottom: 5rem;
        justify-content: flex-start;
      }
    }
  }

  .Card-background--illustration {
    @media (mq.$desktop) {
      height: calc(100% - 60px);
      width: calc(50% - 30px);
      margin-left: 0;
      margin-bottom: 30px;
      margin-right: 30px;
      margin-top: 30px;
      background-position: center;
    }

    @media (mq.$phone) {
      width: calc(50% - 30px);
      height: calc(100% - 60px);
      margin-right: 30px;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    @media (mq.$tablet) {
      width: calc(50% - 40px);
      height: calc(100% - 80px);
      margin-right: 40px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .Card--rubrique {
    min-height: 100%;

    .Card-type {
      margin-left: 0;
      margin-right: 0;
    }

    .Card-bottom,
    .Card-top {
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
    }

    .Card-bottom {
      padding-bottom: 4.5rem;
      justify-content: center;

      @media (mq.$desktop) {
        padding-bottom: 5rem;
        justify-content: flex-start;
      }
    }
  }
}

.CardSlider-slider {
  border-radius: 10px;

  @media (mq.$from-tablet) {
    border-radius: 20px;
  }

  .Card--promoted {
    margin-right: 0;
    max-width: 100%;

    height: 246px;

    @media (mq.$from-tablet) {
      height: 308px;
    }
  }

  .CardConseil {
    height: 246px;

    @media (mq.$from-tablet) {
      height: 308px;
    }
  }

  .swiper-slide {
    height: auto;

    .Card {
      height: 246px;

      @media (mq.$from-tablet) {
        height: 308px;
      }
    }
  }
}

.CardSlider-dots.swiper-pagination {
  padding: 0 30px 10px;
  bottom: 0;

  @media (mq.$tablet) {
    padding: 0 40px 10px;
    bottom: 0;
  }

  @media (mq.$desktop) {
    padding: 0 40px 15px;
    text-align: left;
  }

  .swiper-pagination-bullet {
    background: #fff;
    opacity: 1;
    height: 10px;
    width: 10px;
    border-radius: 20px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

    #{variables.$keyboard} &:focus {
      background-color: colors.$lightGrey;
    }

    @media (mq.$desktop) {
      height: 6px;
      width: 47px;
    }
  }

  .swiper-pagination-bullet-active {
    background: colors.$black;

    #{variables.$keyboard} &:focus {
      background: colors.$black;
    }
  }
}
