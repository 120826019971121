/*! FilterDrawer scss component */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/mixins';

.Drawer {
  position: fixed;
  transition: 0.5s;
  background: #fff;
  z-index: 500;
  width: 100%;

  &[data-drawer-glossary] {
    .Drawer-container {
      padding: 0 20px;
    }
  }
}

.Drawer-content {
  overflow-y: auto;
  @media (mq.$from-tablet) {
    @include mixins.add-scrollbar-style;
  }

  .container,
  .container-inner {
    padding: 0;
  }
}

.Drawer--quiz {
  .Drawer-container {
    @media (mq.$phone) {
      padding-top: 40px
    }
  }

  .Drawer-absoluteButton {
    right: 20px;
    top: 20px;
  }
}


.Drawer[data-drawer-small] {
  padding: 20px 0;
  height: 320px;
  bottom: -320px;

  &[aria-hidden=false] {
    bottom: 0;
  }

  @media (mq.$desktop) {

    height: 280px;

    &[aria-hidden=true] {
      bottom: -280px;
    }
  }

  .Drawer-content {
    max-height: 300px;
    min-height: 140px;
    padding-right: 0;
    
    @media (mq.$from-tablet) {
      min-height: 200px;
      margin-right: 80px;
    }

  }

}

.Drawer[data-drawer-adjust] {
  bottom: -100%;
  max-height: 75vh;

  &[aria-hidden=false] {
    bottom: 0;
  }

  .Drawer-content {
    padding: 0 20px;
    max-height: 65vh;
    &.Drawer-content--mobileFull {
      @media (mq.$phone) {
        padding:0 !important;
      }
    }  
  }
}
.Drawer-content--mobileFull {
  @media (mq.$phone) {
    padding:0 !important;
  }
}  

.Drawer[data-drawer-full] {
  bottom: -100%;
  height: 99%;

  &[aria-hidden=false] {
    bottom: 0;
  }

  .Drawer-content {
    padding: 0 20px;
    height: 89vh;
  }

  &[data-drawer-twoStep] {
    bottom: calc(-100% - 60px);

    &[aria-hidden=false] {
      bottom: 0;
    }
  }
}

.Drawer[data-drawer-twoStep] {
  .Drawer-twoStepButton {
    display: block;
  }
}

.Drawer-absoluteButton {
  margin-bottom: 0;
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 10;
}

.Drawer-twoStepButton {
  margin-bottom: 0;
  position: absolute;
  left: 50%;
  top: -60px;
  transform: translateX(-50%) rotate(-90deg);
  display: none;
  padding: 0;
}



.Drawer--food {
  .Drawer-absoluteButton {
    @media (mq.$phone) {
      top: 22px;
    }

    .Cta .icon {
      @media (mq.$phone) {
        width: 20px;
        height: 20px;
      }
    }
  }
  .Drawer-content .container-inner > div {
    padding-top: 0;
  }

}


// Skeleton variant
// Used to display skeleton drawer content when loading

.Drawer-skeleton {
  min-height: 100%;
  background-repeat: no-repeat;
  background-image:
    linear-gradient(colors.$lightGrey 50px, transparent 0), // Title
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Janvier
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Février
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Mars
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Avril
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Mai
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Juin
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Juillet
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Aout
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Septembre
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Octobre
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Novembre
    linear-gradient(colors.$lightGrey 40px, transparent 0), // Décembre
    linear-gradient(colors.$lightGrey 25px, transparent 0), // Description
    linear-gradient(colors.$lightGrey 300px, transparent 0), // image
    linear-gradient(colors.$lightGrey 50px, transparent 0), // title 
    linear-gradient(colors.$lightGrey 30px, transparent 0), // text line 
    linear-gradient(colors.$lightGrey 30px, transparent 0), // text line 
    linear-gradient(colors.$lightGrey 30px, transparent 0), // text line 
    linear-gradient(colors.$lightGrey 30px, transparent 0), // text line 
    linear-gradient(colors.$lightGrey 30px, transparent 0); // text line 

  background-size:
    250px 22px, // Type
    50px 40px; // Title

  background-position:
    0 0, // Type
    0 60px; // Title

  animation: 1.5s ease 0s infinite alternate opacity;

  @media(mq.$desktop) {


    background-size:
      280px 50px, // Title
      50px 40px, // Janvier
      50px 40px, // Février
      50px 40px, // Mars
      50px 40px, // Avril
      50px 40px, // Mai
      50px 40px, // Juin
      50px 40px, // Juillet
      50px 40px, // Aout
      50px 40px, // Septembre
      50px 40px, // Octobre
      50px 40px, // Novembre
      50px 40px, // Décembre
      420px 38px, // description
      820px 300px, // image
      280px 50px, // title
      820px 30px, // text line
      820px 30px, // text line
      820px 30px, // text line
      820px 30px, // text line
      640px 30px; // text line

    background-position:
      0 0, // Title
      0 65px, // Janvier
      70px 65px, // Février
      140px 65px, // Mars
      210px 65px, // Avril
      280px 65px, // Mai
      350px 65px, // Juin
      420px 65px, // Juillet
      490px 65px, // Aout
      560px 65px, // Septembre
      630px 65px, // Octobre
      700px 65px, // Novembre
      770px 65px, // Décembre
      0 130px, // description
      0 210px, // image
      0 540px, // title
      0 610px, // title
      0 660px, // title
      0 710px, // title
      0 760px, // title
      0 810px; // title
  }
}

@keyframes opacity {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}