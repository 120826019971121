/*! UserDashboardNavigation scss component */

@use 'utilities/colors';
@use 'utilities/mq';


.UserDashboardNavigation {
  position: relative;
  padding: 150px 0 0;
}

.UserDashboardNavigation-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  left: 50%;
  width: 100%;
  max-width: 400px;
  transform: translate(-50%, -60%);

  .icon {
    width: 300px;
    height: 300px;
    color: colors.$greenDark;
  }
}

.UserDashboardNavigation-navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (mq.$upto-tablet) {
    max-width: 600px;
    margin: 0 auto;
  }

  @media (mq.$desktop) {
    flex-direction: row;
  }
  
  //SPFMANBOU-521
  .Cta{
    margin-left: 15px;
    margin-right: 15px;

    > span{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    @media (mq.$upto-tablet) {
      width: 100%;
    }
  }

}