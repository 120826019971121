// -------------------
// Brand/design colors
// -------------------
// Tip: Only create variables for brand colors,
// not for every single possible shade of grey.

$black: #201f2c;
$lightGrey: #ccc;
$lighterGrey: #ddd;

// Blue
$blueDark: #3558A2;
$blue: #417DC4;
$blueLight: #7AB1E8;

// Green
$greenDark: #34CB6A ;
$green: #34CB6A;
$greenLight: #aff3bf;

// Orange
$orangeDark: #FF732C ;
$orange: #FF732C;
$orangeLight: #fce0c5;

// Red
$redDark: #c6473d;
$red: #ea5e5e;
$redLight: #ffcac7; 

// Grey
$greyDark: #34BAB5;
$grey: #34BAB5;
$greyLight: #b8e7e5;

$lowBlueLight: #aad5ff;
$lowBlue: #7AB1E8;
$lowBlueDark: #417DC4;

$mediumBlueLight: #7AB1E8;
$mediumBlue: #417DC4;
$mediumBlueDark: #3558A2;

$highBlueLight: #417DC4;
$highBlue: #3558A2;
$highBlueDark: #1f4088;