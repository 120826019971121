/*! IntroArticle module css component  */

@use 'utilities/mq';
@use 'utilities/colors';

.IntroArticle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: 600;
  border-bottom: 2px solid colors.$lightGrey;

  @media (mq.$desktop) {
    border-bottom: none;
  }
}

.IntroArticle-content {
  position: relative;
  width: 100%;
  border-bottom: none;

  @media (mq.$desktop) {
    width: 60%;
    border-bottom: 2px solid colors.$lightGrey;
  }
}

.IntroArticle-bookmark {
  position: absolute;
  top: 20px;
  right: 20px;
}

.IntroArticle-content--bookmarkable {
  @media (mq.$phone) {
    margin-top: 30px;
  }
}

.IntroArticle-content--noTime {
  .IntroArticle-bookmark {
    @media (mq.$desktop) {
      right: -50px;
    }

    @media (mq.$upto-tablet) {
      top: unset;
      right: 0;
      bottom: -48px;
    }
  }
}

.IntroArticle-share {
  width: 100%;
  margin-bottom: 3rem;

  @media (mq.$tablet) {
    margin-bottom: 5rem;
  }

  @media (mq.$desktop) {
    width: 30%;
    margin-bottom: 0;
  }

  .Share-group {
    max-height: 0;

    @media (mq.$desktop) {
      max-height: 600px;
    }
  }

  .Share-mobile {
    justify-content: flex-start;

    @media (mq.$desktop) {
      justify-content: center;
    }
  }
}

.IntroArticle-contentTime {
  margin-bottom: 2.1rem;
  font-weight: 400;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 3rem;

  svg {
    height: 1.7rem;
    width: 1.7rem;
  }

  @media (mq.$desktop) {
    margin-bottom: 4rem;
    font-size: 1.4rem;
    height: 2rem;

    svg {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
}

.IntroArticle-contentText {
  padding-bottom: 3rem;
  font-size: 1.8rem;
  font-weight: 700;

  @media (mq.$from-tablet) {
    padding-bottom: 6rem;
    font-size: 2rem;
  }
}
