/*!Quiz quote scss component */

@use 'utilities/colors';
@use 'utilities/mq';

.QuizQuote-separator {
  width: 100%;
  height: 2px;
  margin: 40px 0;
  background: rgba(colors.$black, 0.2);

  @media (mq.$from-tablet) {
    margin: 60px 0;
  }
}

.QuizQuote-quote {
  margin-left: 0;
  margin-bottom: 60px;
  text-align: left;

  .Blockquote-header {
    .Blockquote-iconContainer {
      width: 20px;
      margin-left: 10px;
      flex-shrink: 0;
    }

    svg {
      @media (mq.$phone) {
        width: 100%;
      }
    }
  }

  .Blockquote-title {
    font-size: 1.8rem;
  }

  .Blockquote-content {
    @media (mq.$from-tablet) {
      font-size: 1.6rem;
    }
  }

  blockquote.Richtext {
    font-size: 1.6rem;
  }
}
