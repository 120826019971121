/*! User profil scss component */

@use 'utilities/mq';
@use 'utilities/colors';

.UserProfil {
  padding-bottom: 30px;

  @media (mq.$from-tablet) {
    padding-bottom: 60px;
  }

  @media (mq.$phone) {
    padding-top: 40px;
  }
}

.UserProfil-title ,
.UserProfil-logout {
  text-align: center;
  
  @media (mq.$phone) {
    font-size: 2.1rem;
  }
}
.UserProfil-logout--noPadding {
  padding-bottom: 0;
}

.UserProfil-descriptionTitle {
  padding-bottom: 10px;
  text-transform: uppercase;
  font-size: 1.8rem;
  border-bottom: 2px solid rgba(colors.$black, .15);

  @media (mq.$phone) {
    text-align: center;
    font-size: 1.6rem;
  }
}

.UserProfil-descriptions {
  margin-top: 5rem;
}

.UserProfil-description {
  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  @media (mq.$phone) {
    text-align: center;
  }
}

.UserProfil-descriptionValue {
  display: inline-block;
  margin: 1.5rem auto 0;
  border-radius: 50px;
  font-weight: 600;

  @media (mq.$phone) {
    text-align: center;
  }

  .Cta {
    padding: 2px 15px;
    font-weight: 600;
    text-transform: none;
  }

}