// -----------------------------------------------------------------------------
// This file contains all rules for typographie
// -----------------------------------------------------------------------------

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/pageTheme' as theme;

.title1{
  font-size: 3.1rem;
  margin-bottom: 2rem;

  @media (mq.$from-tablet){
    font-size: 5.2rem;
  }
}

.title2{
  font-size: 2.8rem;
  margin-bottom: 2rem;

  @media (mq.$from-tablet){
    font-size: 4.2rem;
  }
}

.title3{
  font-size: 2.5rem;
  margin-bottom: 2rem;

  @media (mq.$from-tablet){
    font-size: 3.6rem;
  }
}
.title4{
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (mq.$from-tablet){
    font-size: 3.2rem;
  }
}
.title5, .title5.titleBackground {
  font-size: 1.8rem;
  margin: 0 0 2rem;

  span {
    padding-inline-start: 0.7rem;
  }
}

.title6, .title6.titleBackground {
  font-size: 2.3rem;
  margin-block: -3rem 1.6rem;

  @media (mq.$desktop){
    font-size: 3rem;
    margin-block: 0 6rem;
  }
}

.titleBackground{
  margin: 0 1.4rem;
  margin-bottom: 2rem;

  >span {
    @include theme.themify {
      background-color: theme.$color-light;
      box-shadow: 1.4rem 0 0 theme.$color-light, -1.4rem 0 0 theme.$color-light;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }
}

.titleBackground--white {
  > span{
    @include theme.themify {
      background-color: #fff;
      box-shadow: 0.7rem 0 0 #fff, -0.7rem 0 0 #fff;
    };
  }
}

.titleBackground--white {
  > span {
    background-color: #fff;
    box-shadow: 0.7rem 0 0 #fff, -0.7rem 0 0 #fff;
  }
}

.titleBackground--grey {
  > span {
    background-color: colors.$grey;
    box-shadow: 0.7rem 0 0 colors.$grey, -0.7rem 0 0 colors.$grey;
  }
}
