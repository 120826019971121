/*! blockquote css component*/

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/pageTheme'as theme;

.Blockquote {
  margin-bottom: 30px;
  margin-left: -14px;

  .icon {
    width: 21px;
    height: auto;
    margin-left: 4px;

    @include theme.themify {
      color: theme.$color-dark;
    }
  }
}

.Blockquote-bookmark {
  position: absolute;
  top: 20px;
  right: 30px;
}

// Adjust the position of theBlockquote with
// a question mark icon
.Blockquote--question {
  .icon {
    position: relative;
    left: 5px;
  }
}

.Blockquote--alone {
  margin: 0;
}

.Blockquote-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Blockquote-title {
  font-weight: 700;
  line-height: 1.2;
  padding-left: 20px;
  font-size: 1.8rem;
  // font-size: 3.2rem;

  // @media (mq.$from-tablet) {
  //   padding-left: 24px;
  // }
}

.Blockquote-content {
  margin: 0 0 0 14px;
  padding: 10px 10px 0 26px;
  border-left: 5px solid;
  font-size: 1.6rem;

  // @media (mq.$from-tablet) {
  //   padding: 10px 10px 0 36px;
  //   font-size: 1.8rem;
  // }

  @include theme.themify {
    border-color: theme.$color-light;
  }
}

.Blockquote-author {
  margin-top: 1rem;
  font-style: italic;
  font-size: 1.5rem;
  opacity: 0.8;

  @media (mq.$from-tablet) {
    font-size: 1.8rem;
  }
}

.Blockquote-warning {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  opacity: 0.8;

  @media (mq.$from-tablet) {
    font-size: 1.8rem;
  }
}

@each $theme, $colors in theme.$activityTheme-colors {
  [data-activity="#{$theme}"] {
    .Blockquote {
      .icon {
        color: map-get($colors, "dark");
      }
    }
    .Blockquote-content {
      border-color: map-get($colors, "light");
    }
  }
}
