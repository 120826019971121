/*! BackButton css component */

@use 'utilities/mq';

.BackButton {
  position: absolute;
  bottom: -70px;
  left: 25px;

  .Cta {
    max-width: 100%;
    line-height: 1;
    overflow: hidden;
    transition:0.25s, .15s color, .15s background-color;
    margin-bottom: 0;

    @media(mq.$phone) {
      width: 17px;
    }
    
    .icon{
      position: relative;
      transition:0.25s, .15s color;
      
      @media(mq.$phone) {
        margin-right: 0;
      }

      @media(mq.$phone) {
        width: 17px;
        height: 17px;
      }
    }
  }

  .Cta-label {
    width: 100%;
    white-space: nowrap;
    opacity: 1;
    overflow: hidden;
    transition: .25s, .15s color;
  }
}