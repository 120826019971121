@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/functions'as f;
@use 'utilities/pageTheme'as theme;

/*! Cover Component */

.Cover {
  position: relative;
  z-index: 1;
  min-height: 240px;
  padding: 155px 20px 120px;
  text-align: center;
  background-image: f.image("/pattern/pattern_grudge_desktop.svg");

  @include theme.themify {
    background-color: theme.$color-base;
  }

  @media (mq.$upto-desktop-navigation) {
    padding: 155px 20px 120px;
  }

  @media (mq.$desktop-navigation) {
    padding: 165px 40px 140px;
  }

  .Breadcrumbs {
    margin-bottom: 20px;
  }
}

.Cover--activity {
  @each $theme, $colors in theme.$activityTheme-colors {
    &.Cover--#{$theme} {
      background-color: map-get($colors, "base");
    }
  }
}

.Cover--resultpage {
  padding: 145px 20px 165px;

  @media (mq.$phone) {
    margin-bottom: 40px;
  }

  @media (mq.$desktop) {
    padding: 145px 40px 165px;
  }
}

.Cover--accountPage {
  padding-bottom: 180px;
}

.Cover-background {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-position: center -40px;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.15;

  @media (mq.$tablet) {
    background-size: 400px;
  }

  @media (mq.$desktop) {
    background-size: 400px;
  }
}

.Cover-background--desktop {
  background-position: top left, bottom right;
}

.Cover-background--override {
  background-size: cover;
  background-position: center;
  opacity: 1;
}

.Cover-background--mobile {
  @media (mq.$phone) {
    background-position: left 40px;
  }
}

.Cover-title {
  // max-width: 240px;
  margin: 0 auto;
  text-align: center;
  font-size: 2.6rem;

  @media (mq.$from-tablet) {
    max-width: 965px;
    font-size: 3.6rem;
  }

  span {
    padding: 0.3rem;
    box-shadow: 0.1rem 0 0 #fff, -0.1rem 0 0 #fff;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    background: #fff;

    @media (mq.$from-tablet) {
      padding: 0.4rem;
    }
  }
}

.Cover-search {
  margin-top: 20px;

  @media (mq.$from-tablet) {
    margin-top: 30px;
  }
}

.Cover-search--resultpage {
  .SearchBar-button {
    display: none;
  }

  .SearchBar-form {
    display: block;
    top: 0;
    opacity: 1;
  }
}
