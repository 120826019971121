/*! Richtext component scss*/

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';
@use 'utilities/pageTheme'as theme;

$transtitionTime: 0.15s;

.Richtext {
  font-size: 1.6rem;

  // Richtext headers
  h2,
  h3,
  h4 {
    margin-bottom: 4rem;
    font-weight: 700;
    line-height: 1.375;
    margin-top: 60px;

    &:first-child {
      margin-top: 0;
      @media (mq.$phone) {
        margin-top: 0;
      }
    }
  }

  h2,
  h3 {
    > span {
      @include theme.themify {
        background-color: theme.$color-light;
        box-shadow: 0.7rem 0 0 theme.$color-light,
          -0.7rem 0 0 theme.$color-light;
      }
    }
  }

  h2 {
    font-size: 2.3rem;

    @media (mq.$from-tablet) {
      font-size: 3rem;
    }
  }

  h3 {
    font-size: 2.1rem;

    @media (mq.$from-tablet) {
      font-size: 2.4rem;
    }
  }

  h4 {
    font-size: 1.6rem;

    @media (mq.$from-tablet) {
      font-size: 1.8rem;

      > span {
        @include theme.themify {
          background-color: theme.$color-light;
          box-shadow: 0.7rem 0 0 theme.$color-light,
            -0.7rem 0 0 theme.$color-light;
        }
      }
    }
  }

  // Richtext texts
  p {
    margin-bottom: 4rem;
  }

  strong,
  b {
    font-weight: 700;
  }

  a:not([class]) {
    font-weight: 600;
    border-bottom: 3px solid colors.$black;
    color: currentColor;
    transition: border $transtitionTime;

    &:hover,
    #{variables.$keyboard} &:focus {
      @include theme.themify {
        border-color: theme.$color-base;
      }
    }
  }

  .Card--promoted {
    margin-left: 0;
    max-width: 625px;

    .Card-bottom,
    .Card-top {
      @media (mq.$from-tablet) {
        height: 200px;
      }
    }

    .Card-background--illustration {
      height: calc(100% - 60px);
      width: calc(50% - 30px);
      margin-left: 0;
      margin-bottom: 30px;
      margin-right: 30px;
      margin-top: 30px;
      background-position: center;
    }
  }

  .FoodKeynumbers-number {
    margin-bottom: 10px;
  }

  .Card--promoted,
  .Card {
    h2,
    h3 {
      margin-top: 0 !important;
      margin-bottom: 2rem;
      font-size: 2rem;
      line-height: 1.8;

      span {
        background-color: #ffffff;
        box-shadow: 0.7rem 0 0 #ffffff, -0.7rem 0 0 #ffffff;
      }
    }

    .Card-type {
      margin-bottom: 0 !important;
    }
  }

  pre {
    padding: 10px;
    border: 1px solid #e5e5e5;
    font-size: 1.6rem;
    background-color: #fafafa;
  }

  // Richtext lists
  ul,
  ol {
    margin-bottom: 1.5em;
  }

  ul li,
  ol li {
    position: relative;
    margin-left: 1em;
    margin-bottom: 1em;
    padding-left: 1.6em;
    list-style: none;

    @media (mq.$from-tablet) {
      margin-left: 2em;
    }

    &::before {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      margin-right: 6px;
      font-weight: 700;

      @include theme.themify {
        color: theme.$color-dark;
      }
    }
  }

  ul {
    li {
      padding-left: 1em;

      &::before {
        content: "\2022";
      }
    }
  }

  ol {
    li {
      padding-left: 1.6em;
      counter-increment: li;

      &::before {
        content: counter(li) ".";
        top: -11px;
        font-size: 2.6rem;

        @media (mq.$desktop) {
          top: -5px;
          font-size: 2.2rem;
        }
      }
    }
  }

  //separator
  .separator {
    width: 100%;
    height: 1px;
    margin: 2rem 0;
    background: colors.$lightGrey;
  }

  //blockquote
  .Blockquote-title {
    margin-bottom: unset;
    margin-top: auto;
    font-size: 1.8rem;
  }

  &.Blockquote-content {
    font-size: 1.6rem;

    @media (mq.$from-tablet) {
      font-size: 1.8rem;
    }

    .ezrichtext-field > *:last-child {
      margin-bottom: unset;
    }
  }

  .SPFPlayer {
    text-align: end;
    max-width: 625px;
  }

  .SPFPlayer-player {
    margin-bottom: 0;
  }

  // Richtext meida player
  .SPFPlayer-transcription {
    color: colors.$black;
    transition: 0.15s;
    background-color: transparent;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: initial;
    text-transform: none;
  }

  //Richtext cards
  .Card {
    .Cta {
      color: colors.$black;
      text-decoration: none;
      transition: 0.15s;

      &:hover,
      #{variables.$keyboard} &:focus {
        color: #fff;
      }
    }
  }

  // Richtext images
  .embed-image {
    // container div
    display: inline-block;

    .ezimage-field {
      // figure
      overflow: hidden;
      display: block;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }

    figcaption {
      margin-top: 1rem;
      font-style: italic;
      font-size: 1.6rem;
      color: #777;
    }
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }
}

@each $theme, $colors in theme.$activityTheme-colors {
  [data-activity="#{$theme}"] {
    .Richtext {
      h2,
      h3 {
        > span {
          background-color: map-get($colors, "base");
          box-shadow: 0.7rem 0 0 map-get($colors, "base"),
            -0.7rem 0 0 map-get($colors, "base");
        }
      }
    }
  }
}
