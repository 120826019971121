/*!
This scss file has the purpose
of overriding ez classes if needed
or add css on ez classes when thoses
classes are global
*/

// add a before to ez-anchor so the element is not hided by the navigation
.ez-has-anchor{
  position: relative;
  z-index: -1;
  
  &::before{
    content:'';
    position: relative;
    width:0;
    height:200px;
    background: transparent;
    margin-top:-200px;
    display: block;
    z-index:-1;
    user-select: none;
    pointer-events: none;
  }
}