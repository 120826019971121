/*! Theming  */
/** Each content type have different theme color, so we group them here to use it in the different components
  * 1. We need a wrapper with [data-theme="myTheme"]
  * 2. Import the file with @use 'utilities/pageTheme' as theme;
  * 3. You can use the mixin like this :
  *    @include theme.themify {
  *     color: theme.$color-base;
  *    };
*/

@use 'utilities/colors';

$themes-colors: (
  'orange': (
    'base': colors.$orange,
    'dark': colors.$orangeDark,
    'light': colors.$orangeLight,
  ),
  'green': (
    'base': colors.$green,
    'dark': colors.$greenDark,
    'light': colors.$greenLight,
  ),
  'blue': (
    'base': colors.$blue,
    'dark': colors.$blueDark,
    'light': colors.$blueLight,
  ),
  'red': (
    'base': colors.$red,
    'dark': colors.$redDark,
    'light': colors.$redLight,
  ),
  'grey': (
    'base': colors.$grey,
    'dark': colors.$greyDark,
    'light': colors.$greyLight,
  ),
) !default;

$activityTheme-colors: (
  'low': (
    'base': colors.$lowBlue,
    'dark': colors.$lowBlueDark,
    'light': colors.$lowBlueLight,
  ),
  'medium': (
    'base': colors.$mediumBlue,
    'dark': colors.$mediumBlueDark,
    'light': colors.$mediumBlueLight,
  ),
  'high': (
    'base': colors.$highBlue,
    'dark': colors.$highBlueDark,
    'light': colors.$highBlueLight,
  ),
);

// Initialize our variables as `null` so that when used outside of `themify`,
// they actually output nothing.
$color-base: null;
$color-dark: null;
$color-light: null;

/// Themify mixin
/// @author Hugo Giraudel
/// @param {Map} $themes [$themes] - Map of themes to use
@mixin themify($themes: $themes-colors) {
  // Create default theme
  & {
    $color-base: map-get($themes, 'orange', 'base') !global;
    $color-dark: map-get($themes, 'orange', 'dark') !global;
    $color-light: map-get($themes, 'orange', 'light') !global;

    // Output user content
    @content;

    $color-base: null !global;
    $color-dark: null !global;
    $color-light: null !global;
  }

  // Iterate over the themes
  @each $theme, $colors in $themes {
    // Create a selector (e.g. `[data-theme="green"] .media`)
    [data-theme="#{$theme}"] & {
      // Set the theme variables with `!global`
      $color-base: map-get($colors, 'base') !global;
      $color-dark: map-get($colors, 'dark') !global;
      $color-light: map-get($colors, 'light') !global;

      // Output user content
      @content;

      $color-base: null !global;
      $color-dark: null !global;
      $color-light: null !global;
    }
  }
}

// @mixin themifyActivity($themes: $activityTheme-colors) {
//   // Create default theme
//   & {
//     $color-base: map-get($themes, 'medium', 'base') !global;
//     $color-dark: map-get($themes, 'medium', 'dark') !global;
//     $color-light: map-get($themes, 'medium', 'light') !global;

//     // Output user content
//     @content;

//     $color-base: null !global;
//     $color-dark: null !global;
//     $color-light: null !global;
//   }

//   // Iterate over the themes
//   @each $theme, $colors in $themes {
//     // Create a selector (e.g. `[data-theme="green"] .media`)
//     [data-theme="#{$theme}"] & {
//       // Set the theme variables with `!global`
//       $color-base: map-get($colors, 'base') !global;
//       $color-dark: map-get($colors, 'dark') !global;
//       $color-light: map-get($colors, 'light') !global;

//       // Output user content
//       @content;

//       $color-base: null !global;
//       $color-dark: null !global;
//       $color-light: null !global;
//     }
//   }
// }