/*! QuizCheckbox scss component */

@use 'utilities/mq';
@use 'utilities/colors';

.QuizInput-inputCheckbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  // Box hover
  &:hover+label {
    background: rgba(colors.$black, 0.05);
  }

  // Box focus
  &:focus+label:before {
    background: rgba(colors.$black, 0.05);
  }

  // Box checked
  &:checked+label {
    background: colors.$green;
  }

  // Box focus
  &:focus+label {
    background: #fafafa;
    // border-color: colors.$black;
  }

  // Change Label on focus
  &:checked:focus+label {
    background: colors.$green;

    &::before {
      background: #fff;
    }
  }

  // Disabled state label.
  &:disabled+label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled+label:before {
    box-shadow: none;
    background: colors.$lighterGrey;
  }

  // Checkmark. Could be replaced with an image
  &:checked+label:after {
    content: '';
    position: absolute;
    left: 13px;
    top: 20px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow:
      2px 0 0 colors.$black,
      4px 0 0 colors.$black,
      4px -2px 0 colors.$black,
      4px -4px 0 colors.$black,
      4px -6px 0 colors.$black,
      4px -8px 0 colors.$black;
    transform: rotate(45deg) translatey(-50%);
    top: 50%;
    left: 10px;
  }
}

.QuizInput-label {
  display: inline-block;
  position: relative;
  padding: 7px 10px 7px 35px;
  border: 1px solid colors.$green;
  border-radius: 10px;
  font-weight: 700;
  font-size: 1.4rem;
  transition: .15s;
  text-transform: uppercase;
  letter-spacing: 0;
  color: colors.$black;
  cursor: pointer;
  text-align: left;

  @media (mq.$phone) {
    width: 100%;
    font-size: 1.2rem;
  }

  // Box.
  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border: 1px solid colors.$green;
    border-radius: 4px;
    background: white;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: .15s;
    top: 50%;
    transform: translatey(-50%);
    left: 6px;
  }
}