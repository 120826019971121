/*! Card */
// --
// Card components can have a color variant, see the sass each functions below
// If no variant color is defined, the card will be orange
// --
// Card also has a skeleton variant used to display it when the card is loading
// You can find it in the end of the file
// --

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/functions';
@use 'utilities/pageTheme'as theme;

// Below is the Regular card style

.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 2.9rem 2.9rem;
  border-radius: 20px;
  overflow: hidden;
  background-image: functions.image("card-texture.png");
  background-color: colors.$orange;

  @media screen and (min-width: 745px) {
    max-width: max(calc((100% / 2) - 2.9rem), 335px);
    height: 246px;
  }

  @media (mq.$desktop) {
    max-width: min(calc((100% / 3) - 2.9rem), 298px);
    height: 430px;
  }

  &:not(.Card--promoted) {
    .Card-background {
      @media (mq.$desktop) {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        overflow: hidden;
      }
    }
  }

  .Cta {
    position: unset;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.Card-informations,
.Card-type {
  margin: 0 0.7rem;
  text-transform: uppercase;
  font-weight: 700;

  span {
    background-color: colors.$orangeLight;
    box-shadow: 0.7rem 0 0 colors.$orangeLight, -0.7rem 0 0 colors.$orangeLight;
  }
}
.Card-informations {
  font-size: 1.6rem;
  height: 2.1rem;

  span {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    padding-block: 0.1rem;
    line-height: 1.2;

    @media (mq.$desktop) {
      padding-block: 0.3rem;
      gap: 1rem;
    }
  }

  @media (mq.$desktop) {
    font-size: 1.2rem;
    line-height: 1.35;
    height: auto;
  }
}
.Card-type {
  font-size: 1.6rem;

  @media (mq.$desktop) {
    font-size: 1.2rem;
  }
}

.Card-type {
  margin-inline-start: 0.7rem;
  height: 2.1rem;
  margin-block-end: 0.3rem;

  span {
    display: inline-block;
    line-height: 2.1rem;
    margin-block-end: 0.3rem;
  }

  @media (mq.$desktop) {
    margin: 0;
    margin-inline-start: 0.7rem;
    margin-block-end: 0.2rem;
    height: 2.3rem;

    span {
      line-height: 2.3rem;
      margin-block-end: 0.3rem;
    }
  }
}

.Card-title {
  overflow-y: hidden;
  max-height: 98px;

  @media (mq.$desktop) {
    max-height: 124px;
  }
}

.Card-top,
.Card-bottom {
  position: relative;
  z-index: 10;
  min-height: unset;
  height: 123px;

  @media (mq.$desktop) {
    height: 215px;
  }
}

.Card-top {
  padding: 2.5rem 2.5rem 0;
  @media (mq.$desktop) {
    padding: 4rem 4rem 0;
  }
}

.Card-bottom {
  padding: 0 2.5rem 2.5rem;
  @media (mq.$desktop) {
    padding: 0 2.4rem 3.4rem;
  }
}

.Card-bottom {
  @media (mq.$desktop) {
    min-height: 156px;
  }
}

.Card-informations {
  .titleBackground {
    font-weight: 700;
  }
}

.Card-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 50%;
  bottom: 0;
  right: 0;
  overflow: hidden;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: 0.3s;

  @media (mq.$desktop) {
    width: 100%;
    height: 50%;
  }
}

.Card-background--illustration {
  background-size: contain;

  @media (mq.$desktop) {
    background-position: bottom;
    height: calc(60% - 30px);
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
  }

  @media (mq.$phone) {
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: calc(50% - 30px);
    height: calc(100% - 60px);
  }
}

.Card-top {
  .titleBackground {
    line-height: 1.4;
    margin-bottom: 0.1rem;

    @media (mq.$upto-tablet) {
      max-width: 75%;
      line-height: 1.35;
      margin-bottom: 0.2rem;
    }

    &.Card-type {
      margin-bottom: 0.2rem;
    }
  }

  .icon {
    height: 17px;
    width: 17px;

    @media (mq.$desktop) {
      width: 22px;
      height: 22px;
    }
  }
}

.Card-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: static;

  .Cta {
    z-index: 100;
    align-self: flex-end;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.2rem;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    border-width: 1px;
    padding: 9px 20px;

    span {
      line-height: normal;
    }

    @media (mq.$from-tablet) {
      font-size: 1.2rem;
    }

    @media (mq.$desktop) {
      font-size: 1.2rem;
      padding: 11px 20px;
    }
  }
}

// Colors
@each $theme, $colors in theme.$themes-colors {
  .Card--#{$theme} {
    background-color: map-get($colors, "base");

    .Card-informations span,
    .Card-type span {
      background-color: map-get($colors, "light");
      box-shadow: 0.7rem 0 0 map-get($colors, "light"),
        -0.7rem 0 0 map-get($colors, "light");
    }
  }
}

@each $theme, $colors in theme.$activityTheme-colors {
  .Card-Intensity--#{$theme} {
    background-color: map-get($colors, "base");

    .Card-informations span,
    .Card-type span {
      background-color: map-get($colors, "light");
      box-shadow: 0.7rem 0 0 map-get($colors, "light"),
        -0.7rem 0 0 map-get($colors, "light");
    }
  }
}

// Below is the promoted card style

.Card--promoted {
  max-width: calc(100% - 2rem);
  height: 308px;

  .Card-background {
    height: 100%;
    width: 50%;
    overflow: hidden;
  }

  .Card-bottom {
    padding: 0 0 2.5rem;
    @media (mq.$desktop) {
      padding: 0 2.4rem 3.4rem;
    }
  }

  .Card-bottom,
  .Card-top {
    max-width: 100%;
    min-height: unset;
    justify-content: flex-start;
  }

  .Card-top {
    .Card-title {
      max-height: 112px;

      @media (mq.$from-tablet) {
        max-height: 124px;
      }
    }

    @media (mq.$from-tablet) {
      max-width: 75%;
    }
  }

  .Card-bottom {
    flex-direction: row;
    justify-content: center;

    @media (mq.$desktop) {
      justify-content: flex-start;
    }

    @media (mq.$desktop) {
      padding: 0 4rem 5rem;
    }
  }

  .Card-title {
    @media (mq.$phone) {
      font-size: 1.8rem;
    }
  }

  // Promoted Rubrique Card
  &.Card--rubrique {
    min-height: unset;

    .Card-bottom,
    .Card-top {
      max-width: 100%;
      // min-height: 123px;

      // @media (mq.$from-tablet) {
      //   min-height: 154px;
      // }

      .Card-type {
        margin-left: 0.7rem;
      }
    }
    .Card-title.titleBackground {
      padding-inline: 0.7rem;
    }

    .Card-bottom {
      @media (mq.$desktop) {
        justify-content: center;
      }
    }
  }
}

// Rubrique variant
.Card--rubrique {
  position: relative;
  justify-content: flex-start;
  min-height: unset;
  overflow: visible;
  height: 246px;
  // margin-top: 24px;

  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    background-color: #fff;

    @media (mq.$desktop) {
      border-radius: 20px;
    }
  }

  &::after {
    width: calc(100% - 24px);
    bottom: 6px;
  }

  &::before {
    width: calc(100% - 48px);
    bottom: 12px;
  }

  @media (mq.$desktop) {
    height: 430px;
    text-align: center;
  }

  .Card-background {
    height: 100%;
    width: 100%;
    border-radius: 10px;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }

    @media (mq.$desktop) {
      border-radius: 20px;
    }
  }

  .Card-bottom,
  .Card-top {
    min-height: unset;
    text-align: center;
    height: initial;

    // @media (mq.$phone) {
    //   height: 123px;
    //   min-height: 123px;
    // }
  }

  .Card-top {
    display: flex;
    flex-direction: column;
    flex: 1;

    .Card-title {
      margin-left: 0;
      margin-right: 0;
      font-size: 2.1rem;
      max-height: 112px;

      @media (mq.$desktop) {
        font-size: 1.8rem;
        max-height: 124px;
      }
    }

    @media (mq.$desktop) {
      justify-content: center;
    }
  }

  .Card-bottom {
    @media (mq.$desktop) {
      flex: 0;
      min-height: auto;
      // padding-bottom: 60px;
    }
  }

  .titleBackground {
    @media (mq.$upto-tablet) {
      max-width: 100%;
    }
  }

  .title5 span,
  .title5.titleBackground span {
    padding-inline: 0;
  }
}

.Card--rubriquePro {
  .Card-type {
    background-color: colors.$greyLight;
  }
}

// Skeleton variant
// Used to display skeleton card when loading

.Card--skeleton {
  min-height: 300px;
  background-color: #bbb;
  background-repeat: no-repeat;
  background-image: linear-gradient(white 24px, transparent 0),
    // Type
      linear-gradient(white 38px, transparent 0),
    // Title
      linear-gradient(white 38px, transparent 0),
    // Title second ligne
      linear-gradient(#bbb 100%, transparent 0); // BG
  background-size: 150px 22px,
    // Type
      200px 22px,
    // Title
      100px 22px,
    // Title second ligne
      100% 100%; // BG
  background-position: 30px 30px,
    // Type
      30px 60px,
    // Title
      30px 90px,
    // Title second ligne
      0 0; // BG

  animation: 1.5s ease 0s infinite alternate opacity;

  @media (mq.$desktop) {
    min-height: 500px;
    background-size: 150px 22px,
      // Type
        280px 38px,
      // Title
        100px 38px,
      // Title second ligne
        100% 100%; // BG
    background-position: 30px 30px,
      // Type
        30px 60px,
      // Title
        30px 105px,
      // Title second ligne
        0 0; // BG
  }
}

@media (mq.$tablet) {
}
@media (mq.$desktop) {
}

.Card--orange-with-image:not(.Card--promoted) {
  display: grid;
  grid-template-rows: initial;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  height: 246px;

  @media (mq.$desktop) {
    // height: 492px;
    height: 430px;
    grid-template-rows: min-content 1fr 80px;
    grid-template-columns: initial;
  }

  .Card-top {
    height: initial;
    position: initial;

    @media (mq.$desktop) {
      padding: 4rem 4rem 0 4rem;
    }

    .Card-title {
      position: absolute;
      width: 70%;
      overflow-y: hidden;
      margin: 0;
      // padding-inline: 0.7rem;

      @media (mq.$desktop) {
        width: 100%;
        position: initial;
        max-height: 124px;
      }
    }
    .Card-type {
      margin-inline-start: 0.7rem;
      height: 2.1rem;
      margin-block-end: 0.3rem;

      span {
        display: inline-block;
        line-height: 2.1rem;
        margin-block-end: 0.3rem;
      }

      @media (mq.$desktop) {
        margin: 0;
        margin-inline-start: 0.7rem;
        margin-block-end: 0.2rem;
        height: 2.3rem;

        span {
          line-height: 2.3rem;
          margin-block-end: 0.3rem;
        }
      }
    }
  }

  .Card-picture {
    min-height: 0;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    background-color: #f1a36e;

    @media (mq.$desktop) {
      background-color: initial;
    }

    img {
      object-fit: contain;
      height: 100%;
    }
  }

  .Card-bottom {
    width: 100%;
    min-height: initial;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media (mq.$desktop) {
      width: auto;
      position: initial;
      bottom: initial;
      left: initial;
      transform: initial;
      height: 80px;
      padding: 0 2.4rem 3.4rem;
    }
  }
}

.Card--orange-with-image.Card--promoted {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .Card-top {
    position: static;

    .Card-title {
      position: absolute;
      width: 70%;

      overflow: hidden;
      max-height: 108px;

      @media (mq.$from-tablet) {
        max-height: 122px;
      }
    }
  }

  .Card-picture {
    padding: 1.5rem;
    background-color: #f1a36e;

    @media (mq.$desktop) {
      background-color: initial;
    }

    img {
      object-fit: contain;
      height: 216px;
      width: 100%;

      @media (mq.$from-tablet) {
        height: 278px;
      }
    }
  }

  .Card-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media (mq.$desktop) {
      left: 0;
      transform: translateX(0);
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

// Video Card
.Card--video {

  height: 246px;

  @media (mq.$desktop) {
    height: 430px;
  }

  .Card-background {
    width: 100%;
    height: 100%;
  }

  .CardVideo-playerButton--svg {
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    transform: translate(-50%, 40%);
    cursor: pointer;

    @include theme.themify {
      background: #fff;
      transition: background 0.3s;

      &:hover {
        background: theme.$color-base;
        transition: background 0.3s;

        svg {
          path {
            fill: #fff;
            transition: fill 0.3s;
          }
        }
      }
    }

    svg {
      width: 50px;
      height: 50px;

      path {
        fill: #000;
        transition: fill 0.3s;
      }
    }

    @media (mq.$desktop) {
      transform: translate(-50%, -50%);
    }
  }
}
