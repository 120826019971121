/*! Hamburger css component */

@use 'utilities/mq';
@use 'utilities/colors';

.Hamburger {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  padding: 11px;
  border-radius: 50%;
  background: colors.$black;
  transition: 0.15s;
  outline: none;

  .Hamburger-line {
    transform: rotate(0)
  }

  &[aria-hidden=true] {
    display: none;
  }

  &[data-navigation=open] {
    .Hamburger-line {
      &:nth-child(1) {
        top: 23px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        width: 0;
      }

      &:nth-child(3) {
        top: 23px;
        transform: rotate(-45deg);
      }
    }
  }

  @media (mq.$phone) {
    transform: scale(0.8);
  }
}

.Hamburger-line {
  position: absolute;
  width: 27px;
  height: 3px;
  border-radius: 10px;
  background-color: #fff;
  transition: 0.2s;

  &:nth-child(1) {
    top: 14px;
  }

  &:nth-child(2) {
    top: 23px;
  }

  &:nth-child(3) {
    top: 32px;
  }
}