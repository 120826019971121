@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/pageTheme' as theme;

.Recommendation {
  margin-bottom: 4rem;
}

.Recommendation-title {
  margin-bottom: 2.1rem;
  padding: 5px 0;
  text-align: center;

  @media(mq.$from-tablet) {
    margin-bottom: 4.2rem;
  }

  .icon {
    width: 2.2rem;
    height: 2.2rem;
    vertical-align: sub;

    @media(mq.$from-tablet) {
      width: 4.2rem;
      height: 4.2rem;
    }
  }

  span {
    padding: 3px 0 5px;
  }
}

.Recommendation-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px;
  
  @media(mq.$from-tablet) {
    margin-left: -20px;
  }
}

.Recommendation-item {
  flex: 0 0 calc(50% - 1rem);
  max-width: 270px;
  margin-left: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  
  @media(mq.$from-tablet) {
    flex: 0 0 calc(50% - 2rem);
    flex: 0 0 25%;
    margin-left: 2rem;
    margin-bottom: 4rem;
  }

  .Cta{
    text-transform:none ;
  }
}

.Recommendation-img {
  display: block;
  margin: 0 auto 2rem;;
}

.Recommendation-caption {
  margin-top: .8rem;
  font-size: 1.4rem;
  font-weight: 400;
  font-style: italic;

  @media(mq.$from-tablet) {
    margin-top: 1.4rem;
  }
}