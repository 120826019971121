/*! foodlistdrawer scss component */


.Drawer--food{
  .FoodSeason-title{
    text-transform: capitalize;
  }

  .FoodSeason-caption{
    text-transform: lowercase;
    
    &::first-letter{
      text-transform: uppercase;
    }
  }
  .container{
    max-height:none;
  }
}