/*! Navigation component css */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';

.FooterNavigation {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 35px;
  border-bottom: 2px solid rgba(colors.$black, 0.2);

  @media (mq.$phone) {
    display: none;
  }

  @media (mq.$desktop) {
    flex-wrap: nowrap;
  }
}

.FooterNavigation-column {
  &:last-child {
    width: 50%;

    @media (mq.$desktop) {
      position: relative;
      // padding-left: 150px;
      padding-left: 6vw;
      width: 30%;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 2px;
        height: 80%;
        background-color: rgba(colors.$black, 0.2);
        transform: translateY(-50%);
      }
    }
  }

  &:not(:last-child) {
    width: 50%;
    padding-right: 20px;

    @media (mq.$desktop) {
      width: 30%;
    }
  }
}

.FooterNavigation-title {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.FooterNavigation-linksContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;

  @media (mq.$desktop) {
    margin-bottom: 10px;
  }
}

.FooterNavigation-link {
  display: inline-block;
  padding-bottom: 1px;
  font-size: 1.4rem;
  font-weight: 600;

  &:not(:last-child) {
    margin-bottom: 9px;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-color: colors.$green;
    text-decoration-thickness: 2px;
  }
}
