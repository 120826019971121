/*! Navigation component css */

@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/variables';

.Navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px 0 0;
  border-bottom: 1px solid transparent;
  border-bottom-right-radius: 60px;
  border-bottom-left-radius: 0;
  background: #fff;
  transition: 0.15s;

  &[data-navigation=open] {
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &[data-last-navigation-open="open"] {
    .Navigation-tools {
      opacity: 0;
    }
  }

  @media (mq.$desktop-navigation) {
    justify-content: space-between;
    padding: 0 30px;
    border-bottom-left-radius: 60px;
  }

  @media (mq.$phone) {
    border-bottom-left-radius: 60px;
    padding-left: 0;
  }

}

// Navigation search 
.Navigation {
  .SearchBar {
    margin: 0 10px;

    .Cta {
      border: 2px solid transparent;
    }

    @media (max-width: mq.$layout-navigation) {
      margin: 0;
    }

    .SearchBar-form {
      &[aria-hidden=false] {
        @media (max-width: mq.$layout-navigation) {
          padding: 20px;
        }
      }

      @media (max-width: mq.$layout-navigation) {
        position: absolute;
        z-index: 10000;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
        background: #fff;
      }
    }

    .SearchBar-submission {
      @media (max-width: mq.$layout-navigation) {
        margin-left: 0;
      }
    }
  }

  .SearchBar-formRow {
    @media (max-width: mq.$layout-navigation) {
      flex-direction: column;
    }
  }

  .SearchBar-input {
    border-width: 2px;

    @media (max-width: mq.$layout-navigation) {
      margin-bottom: 20px;

    }
  }

  .SearchBar-inputContainer {
    @media (max-width: mq.$layout-navigation) {
      .icon {
        top: calc(50% - 10px);
      }
    }
  }

  .SearchBar-form {
    position: relative;
    top: 0;
    padding: 18px 0;
  }

}

.Navigation--logoOnly {
  @media (mq.$phone) {
    justify-content: center;
  }

  .Navigation-logo.is-mobile {
    @media (mq.$phone) {
      right: 0;
    }

    @media (max-width: mq.$layout-small) {
      width: 190px;
      min-width: 190px;
    }

    @media (mq.$phoneXS) {
      width: 190px;
      min-width: 190px;
    }
  }
}

.Navigation-logo {
  position: relative;
  display: block;
  flex-shrink: 0;
  right: 80px;
  height: 80px;
  width: 170px;
  min-width: 150px;
  overflow: hidden;
  opacity: 1;
  transition: 0.5s;

  @media (mq.$desktop-navigation) {
    right: 0;
  }

  @media (mq.$phoneXS) {
    right: 45px;
    width: 110px;
  }

  &[data-navigation=open] {
    min-width: 0;
    opacity: 0;
  }

  &[data-navigation=close] {
    @media (mq.$tablet) {
      width: 190px;
    }

    @media (max-width: mq.$layout-small) {
      width: 140px;
      min-width: 140px
    }

    @media (mq.$phoneXS) {
      width: 110px;
      min-width: 110px
    }
  }


  &.is-desktop {
    height: auto;
    display: none;

    @media (mq.$from-tablet) {
      display: block;
    }
  }

  &.is-mobile {
    display: flex;
    align-items: center;

    @media (mq.$from-tablet) {
      display: none;
    }
  }

  .icon {
    display: block;
    width: 100%;
    min-width: 100px;
  }

}

.Navigation-container {
  position: fixed;
  z-index: -1;
  top: 0;
  right: -400px;
  height: 100%;
  width: 400px;
  max-width: 100%;
  padding-top: 111px;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: opacity 0.2s, right 0.4s;
  
  @media (mq.$desktop-navigation) {
    position: static;
    z-index: unset;
    display: flex;
    align-items: center;
    width: auto;
    padding-top: 0;
    overflow-y: unset;
    box-shadow: unset;
    overflow: unset;
    background-color: transparent;
  }
  
  @media (mq.$phone) {
    padding-top: 79px;
    width: mq.$layout-small;
  }

  &[data-navigation=close] {
    right: -400px;

    @media (mq.$phone) {
      right: - mq.$layout-small
    }
  }

  &[data-navigation=open] {
    right: 0;
  }
}

.Navigation-mobile {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  width: 110px;
  height: 100%;
  padding: 0 30px;
  border-bottom: 1px solid transparent;
  border-bottom-right-radius: 35px;
  background: #fff;
  transition: 0.5s, opacity .15s .15s;

  &[aria-hidden="true"] {
    opacity: 0;
    pointer-events: none;
  }

  // IE 11
  @media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
    top: 0;
  }

  @media (mq.$desktop-navigation) {
    background: transparent;
    display: none;
  }

  @media (mq.$phoneXS) {
    width: 70px;
    padding: 0 10px;
  }

  &[data-navigation=open] {
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    border-bottom-right-radius: 0;
    justify-content: space-between;
  }

  .Hamburger {
    flex-shrink: 0;
  }
}

.Navigation-searchbar {
  max-width: 100%;
  margin-right: 1rem;
  overflow: hidden;
  transition: 0.5s;

  @media (mq.$upto-desktop-navigation) {
    width: 100%;

    .Cta {
      width: 100%;
      span {
        justify-content: flex-start;
      }
    }
  }

  &[data-navigation=open] {
    padding: 15px;
  }

  &[data-navigation=close] {
    max-width: 0;
  }

  >div {
    display: flex;
    align-items: center;
  }

  .Cta {
    margin-bottom: 0;
    padding-right: 80px;
  }
}

.Navigation-links {
  display: flex;
  flex-direction: column;
  transition: .15s;

  @media (mq.$desktop-navigation) {
    align-items: center;
    flex-direction: row;
  }

  >li {

    @media (mq.$desktop-navigation) {
      position: static;
    }
  }
}

.Navigation-link {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0 22px;
  margin: 0 20px;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: currentColor;
  outline: none;

  span {
    @media (mq.$from-tablet) {
      padding-bottom: 3px;
      border-bottom: 5px solid transparent;
    }
    border-bottom: none;
    transition: border 0.15s ease;
  }

  #{variables.$keyboard} &:focus span {
    border-color: colors.$green;
  }

  &[aria-expanded=true] span {
    border-color: colors.$green;
  }

  &[aria-expanded=false] {
    color: currentColor;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    border-color: colors.$green;

    >.icon {
      color: colors.$green;
    }
  }

  @media (mq.$tablet) {
    margin: 0 40px;
  }
  
  @media (mq.$phone) {
    padding:15px 0;
  }

  @media (mq.$desktop-navigation) {
    display: block;
    border-bottom: unset;
    margin: unset;
    padding: 30px 15px;
  }

  @media(min-width: mq.$layout-large) {
    padding: 30px 25px;
    font-size: 1.6rem;
  }

  >.icon {
    display: block;
    margin-bottom: 8px;
    transition: 0.15s;

    @media (mq.$desktop-navigation) {
      display: none;
    }
  }
}

.Navigation-homelink {
  display: block;

  @media (mq.$desktop-navigation) {
    display: none;
  }
}

.Navigation-extraPadding {
  padding-right: 0;

  @media (mq.$desktop-navigation) {
    padding-right: 25px;
  }

  @media(min-width: mq.$layout-large) {
    padding-right: 40px;
  }
}

.Navigation-separator {
  position: relative;
  padding-left: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -40px;
    width: calc(100% + 80px);
    height: 1px;
    background-color: colors.$lighterGrey;
    transform: translateY(-50%);
  }

  @media (mq.$desktop-navigation) {
    padding-left: 25px;

    &::before {
      top: 50%;
      left: 0;
      width: 2px;
      height: 50%;
      transform: translateY(-50%);
    }
  }

  @media(min-width: mq.$layout-large) {
    padding-left: 40px;
  }

}

.Navigation-access {
  padding: 20px;

  @media (mq.$desktop-navigation) {
    padding: 0;
  }

  .Cta {
    margin-bottom: 0;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  ul li {

    &:not(.Navigation-accountMobile) {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (mq.$desktop-navigation) {
        justify-content: space-between;
      }
    }

    >div {
      display: flex;
      align-items: center;
      flex-grow: 1;
      font-size: 1.8rem;
      font-weight: 700;

      >span {
        @media (mq.$desktop-navigation) {
          display: none;
        }
      }

      .Cta {
        margin-right: 1.5rem;

        @media (mq.$desktop-navigation) {
          margin-right: 0.5rem;
        }
      }
    }

    @media (mq.$desktop-navigation) {
      display: flex;
    }

    >.Cta {
      &:not(:last-child) {
        margin-right: 1rem;

        @media (mq.$desktop-navigation) {
          margin-right: 0.5rem;
        }
      }

      &:first-child {
        display: none;

        @media (mq.$desktop-navigation) {
          display: inline-block;
        }
      }
    }
  }
}

.Navigation-accessContainer {
  padding-top: 20px;

  @media (mq.$desktop-navigation) {
    padding-top: 0;
  }
}

.Navigation-additionalLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  border-bottom: 1px solid #f0f0f0;
  background: #f0f0f0;

  @media (mq.$tablet) {
    padding: 40px;
  }

  @media (mq.$desktop-navigation) {
    display: none;
  }

  .Cta {
    width: 100%;
    padding: 12px 17px;
    font-size: 1.4rem;

    span {
      display: flex;
      justify-content: space-between;
    }

    .icon {
      width: 10px;
      height: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Navigation-subLinksContainer {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  z-index: 1000;
  left: 0;
  top: 111px;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-bottom: none;
  border-top: none;
  background: #fff;
  transition: opacity 0.2s, left 0s 0s;

  @media (mq.$phone) {
    top: 79px;
    height: calc(100% - 80px);
  }

  @media (mq.$desktop-navigation) {
    top: -500px;
    overflow: hidden;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
  }

  &[aria-hidden=true] {
    transition: opacity 0.2s, left 0s .2s;
    opacity: 0;
    left: 100%;
  
    @media (mq.$upto-desktop-navigation) {
      height: 0;
    }

    @media (mq.$desktop-navigation) {
      top: -700px;
      pointer-events: none;
    }

    .Navigation-tools {
      display: none;
    }
  }

  &[aria-hidden=false] {
    transition: opacity 0.2s;
    opacity: 1;
    justify-content: flex-end;

    .Navigation-tools {
      @media (mq.$upto-desktop-navigation) {
        width: 100%;
      }
    }

    @media (mq.$desktop-navigation) {
      justify-content: unset;
      top: 100%;
      height: unset;
      border-top: 1px solid colors.$lighterGrey;
    }
  }

  @media (mq.$desktop-navigation) {
    flex-direction: row;
    z-index: -1;
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
  }
}

.Navigation-tools {
  width: 100%;
  transition: opacity .15s;

  @media (mq.$desktop) {
    width: 30%;
  }

  .Card {
    width: 100%;
    max-width: 100%;
    height: 300px;
    margin: 0;
    border-radius: 0;
    border-bottom-left-radius: 0;

    @media (mq.$upto-desktop-navigation) {
      border-bottom-left-radius: 0;
    }

    .Cta {
      font-size: 1.4rem;
      border: none;
      background: colors.$black;
      color: #fff;

      &:hover,
      #{variables.$keyboard} &:focus {
        background: #fff;
        color: colors.$black;
      }
    }
  }

  .Card-background {
    height: 100%;
    width: 100%;
  }

  .Card:not(.Card--promoted) .Card-background {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .Card-bottom{
    height: 110px;
    min-height: 110px;
  }
  
  .Card-top {
    height: 190px;
    min-height: 190px;
  }

  .Card-bottom {
    justify-content: flex-start;

    @media (mq.$desktop-navigation) {
      padding-top: 15px;

      .Cta {
        // align-self: flex-start;
        margin-bottom: 20px;
      }
    }
  }

  .Card-top {

    @media (mq.$desktop-navigation) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 15px;
    }

    .icon {
      width: 10px;
      height: 11px;
    }
  }

  .Card-title {
    font-size: 2.4rem;
  }

  .Card-informations,
  .Card-type {
    font-size: 1.4rem;
  }
}

.Navigation-subLinks {
  position: static;
  width: 100%;
  padding: 20px 0;
  transition: 0.15s;

  @media (mq.$desktop-navigation) {
    width: 35%;
    // max-height: 60vh;
    overflow-y: auto;
  }

}

.Navigation-subLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 30px;
  font-size: 1.6rem;
  font-weight: 600;
  transition: 0.15s;
  color: currentColor;
  outline: none;

  &[data-sublink-menu] {
    span {
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: transparent;
      text-decoration-thickness: 5px;
      transition: text-decoration .25s ease, color .25s ease;
    }

    &:hover,
    #{variables.$keyboard} &:focus {
      span {
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-color: colors.$green;
        text-decoration-thickness: 5px;
      }

      .icon {
        color: colors.$greenDark;
      }
    }
  }

  &[aria-expanded=true] {
    span {
      color: colors.$greenDark;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: colors.$green;
      text-decoration-thickness: 5px;
    }

    .icon {
      color: colors.$greenDark;
    }
  }

  &[aria-expanded=false] {
    color: currentColor;
  }

  &:not(.Navigation-subLink--mobile) {

    &:hover,
    #{variables.$keyboard} &:focus {
      span {
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-color: colors.$green;
        text-decoration-thickness: 5px;

      }
    }

    .icon {
      margin-bottom: 3px;
    }
  }

  span {
    border-bottom: 2px solid transparent;
    transition: border .15s ease, color .15s ease;
  }

  .icon {
    color: currentColor;
  }
}

.Navigation-subLink--mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (mq.$desktop-navigation) {
    display: none;
    visibility: hidden;
  }

  .Cta {
    margin-bottom: 2rem;
    padding: 8px 16px;
    font-size: 1.4rem;
    line-height: 1;

    #{variables.$keyboard} &:focus {
      border-color: colors.$black;
    }

    span {
      padding: 0;
      border: none;
    }
  }

  a {
    font-size: 1.8rem;
    font-weight: 700;
    color: colors.$green;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 5px;

    &:hover,
    #{variables.$keyboard} &:focus {
      span {
        border: none;
      }
    }
  }

  .Cta {
    margin-bottom: 2rem;
  }
}


.Navigation-lastLinks {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px 30px 30px 30px;
  background: #fff;
  box-shadow: none;
  opacity: 0;
  transition: 0.5s, z-index 0s;

  @media (mq.$phone) {
    height: calc(100% - 80px);
  }

  @media (mq.$desktop-navigation) {
    box-shadow: inset 7px 7px 15px 0px rgba(50, 50, 50, 0.05);
    background: #f5f5f5;
    left: 65%;
    width: 35%;
    // max-height: 60vh;
    overflow-y: auto;
  }

  &[aria-hidden=true] {
    opacity: 0;
    z-index: -1;
    transition: 0.5s, z-index 0.5s;
  }

  &[aria-hidden=false] {
    opacity: 1;
    z-index: 10;
  }
}

.Navigation-lastLink {
  display: block;
  padding: 8px 0;
  font-size: 1.6rem;
  font-weight: 600;
  outline: none;
  color: currentColor;
  transition: 0.15s;

  span {
    padding-bottom: 3px;
    border-bottom: 2px solid transparent;
    transition: border .25s ease;
  }

  @media (mq.$desktop-navigation) {
    padding: 8px 0;
  }

  &:hover,
  #{variables.$keyboard} &:focus {
    span {
      border-color: colors.$green;
    }
  }
}

.Navigation-lastLink--mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;

  @media (mq.$desktop-navigation) {
    display: none;
  }
  
  a {
    font-size: 1.8rem;
    font-weight: 700;
    color: colors.$green;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 5px;
    
    @media (mq.$upto-desktop-navigation) {
      line-height: 1.8
    }
    
    &:hover,
    #{variables.$keyboard} &:focus {
      span {
        border: none;
      }
    }
  }

  .Cta {
    padding: 8px 16px;
    margin-bottom: 2rem;
    line-height: 1;
    font-size: 1.4rem;

    &:hover,
    #{variables.$keyboard} &:focus {
      .icon {
        color: #fff;
      }
    }

    span {
      padding: 0;
      border: none;
    }
  }

}

.Navigation-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  animation: hideOverlay .5s forwards;

  &[data-navigation=open] {
    animation: showOverlay .5s forwards;
  }
}

@keyframes showOverlay {
  0% {
    visibility: visible;
  }

  5% {
    background-color: transparent;
  }

  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@keyframes hideOverlay {
  0% {
    background-color: rgba(0, 0, 0, 0.3);
  }

  95% {
    background-color: transparent;
  }

  100% {
    visibility: hidden;
  }
}

.Navigation-userMenuContainer {
  position: relative;

  .Navigation-access & {
    @media(mq.$upto-desktop-navigation) {
      display: none;
    }
  }

  @media(mq.$desktop-navigation) {
    display: flex;
  }

  [data-user-menu-button] {
    display: none;

    @media(mq.$desktop-navigation) {
      display: inline-block;
    }
  }

  >.Cta {


    &[aria-expanded=true] {
      background-color: colors.$green;

      .icon {
        color: colors.$black;
      }

    }

    >* {
      user-select: none;
      pointer-events: none;
    }
  }
}

.Navigation-userMenu {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: calc(100% + 20px);
  border-radius: 10px;
  border: 1px solid colors.$greyLight;
  box-shadow: 0 0 10px rgba(colors.$black, .25);
  background: #fff;
  opacity: 1;
  visibility: visible;
  overflow: hidden;
  transform: translateX(-50%);
  transition:
    opacity .15s ease-in-out,
    top .15s ease-in-out,
    visibility 0s linear;

  &[aria-hidden=true] {
    top: calc(100% + 50px);
    opacity: 0;
    visibility: hidden;
    transition:
      opacity .15s ease-in-out,
      top .15s ease-in-out,
      visibility 0s linear .15s;
  }

  li:last-child {
    .Navigation-userMenuLink {
      background-color: rgba(colors.$greyLight, .3);

      #{variables.$keyboard} &:focus,
      &:hover {
        background-color: rgba(colors.$greyLight, .6);
      }
    }
  }
}

.Navigation-userMenu-linkContainer {
  padding: 10px 0;
}

.Navigation-userMenuLink {
  display: flex;
  align-items: center;
  min-width: 220px;
  padding: 10px 20px;
  color: colors.$black;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color .2s;

  .icon {
    height: 30px;
    width: 30px;
    padding: 6px;
    margin-right: 10px;
    border-radius: 50%;
    color: white;
    background: colors.$black;
  }

  #{variables.$keyboard} &:focus,
  &:hover {
    background-color: rgba(colors.$greyLight, .3);
  }

}

.Navigation-accountMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
  border-bottom: 1px solid colors.$greyLight;

  @media(mq.$upto-desktop-navigation) {
    [data-user-connect="true"] & {
      justify-content: space-between;
    }
  }

  .Navigation-access & {
    @media(mq.$desktop-navigation) {
      display: none;
    }
  }
}

.Navigation-accountMobileLink {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;

  #{variables.$keyboard} &:focus,
  &:hover {
    .icon {
      background-color: colors.$green;
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-right: 10px;
    border-radius: 50%;
    color: white;
    background-color: colors.$black;
    transition: background-color .2s;
  }
}