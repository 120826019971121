/*! QuizPush scss component */
@use 'utilities/mq';

.QuizPush {
  margin-top: 40px;
  padding-bottom: 30px;

  @media (mq.$from-tablet) {
    margin-top: 60px;
    padding-bottom: 50px;
  }
  @media (mq.$desktop) {
    padding-inline: 0;
    max-width: 800px;
    margin-inline: auto;
  }
}

.QuizPush-title {
  text-align: center;
  padding-inline: 3rem;

  &.title2 {
    font-size: 2.1rem;
    margin-bottom: 0;

    @media (mq.$desktop) {
      padding-inline: 0;
      font-size: 2.8rem;
    }
  }
}

.QuizPush-button {
  padding-top: 2rem;
  text-align: center;

  @media (mq.$desktop) {
    padding-top: 4rem;
  }
}
